import "./regular-datepicker-children-component.scss"
import { Alert } from "@mui/material";
import DatepickerHeaderComponent from "../../datepicker-header/datepicker-header-component";

const RegularDatepickerChildrenComponent = ({
    pages,
    departureDateFlexibility,
    handleTravelFlexibility,
    contentfulSearchBar,
    setIsOpen,
    contentfulButtons,
    handleClick,
    isDateRangeValid,
    showPriceCalendarFunction,
    isMobile,
    isRegularCalendar,
    setIsRegularCalendar,
    defaultSearchUsed
}) => {
  return (
    <>
      <>
        <Alert className="date-range-info" severity="info">
          {contentfulSearchBar &&
            contentfulSearchBar?.maximumDateRange}
        </Alert>
        {pages.results.some((route) =>
          window.location.pathname.includes(route)
        ) && (
          <div className="date-range-modifiers">
            <button
              className={`date-modifier-button ${
                departureDateFlexibility === 0 && "flexibility-button-selected"
              }`}
              onClick={() => handleTravelFlexibility(0)}
            >
              {contentfulSearchBar?.flexibilityExactDate}
            </button>
            <button
              className={`date-modifier-button ${
                departureDateFlexibility === 1 && "flexibility-button-selected"
              }`}
              onClick={() => handleTravelFlexibility(1)}
            >
              &#177; {contentfulSearchBar?.flexibilityOneDay}
            </button>
            <button
              className={`date-modifier-button ${
                departureDateFlexibility === 2 && "flexibility-button-selected"
              }`}
              onClick={() => handleTravelFlexibility(2)}
            >
              &#177; {contentfulSearchBar?.flexibilityTwoDays}
            </button>
            <button
              className={`date-modifier-button ${
                departureDateFlexibility === 3 && "flexibility-button-selected"
              }`}
              onClick={() => handleTravelFlexibility(3)}
            >
              &#177; {contentfulSearchBar?.flexibilityThreeDays}
            </button>
          </div>
        )}
      </>
      <div className="datepicker-button-container regular-datepicker-button-container">
        <button onClick={() => setIsOpen(false)} className="button">
          {contentfulButtons?.cancel}
        </button>
        <button
          onClick={handleClick}
          className={`${
            isDateRangeValid
              ? "button"
              : "save-button-disabled button-primary-medium"
          }`}
        >
          {contentfulButtons?.save}
        </button>
      </div>
      {showPriceCalendarFunction && (
        <div className="mobile-datepicker-header">
          <DatepickerHeaderComponent
            setIsRegularCalendar={setIsRegularCalendar}
            isRegularCalendar={isRegularCalendar}
            contentfulSearchBar={contentfulSearchBar}
            defaultSearchUsed={defaultSearchUsed}
          />
        </div>
      )}
    </>
  );
};

export default RegularDatepickerChildrenComponent;

import {SearchBarContentComponent} from "./search-bar-content/search-bar-content.component";
import {useContext, useEffect, useState} from "react";
import {breakpoints} from "../../common/constants/breakpoints";
import ContentfulContext from "../../store/contentful/contentful-context";
import { getContentfulByContentType } from "../../services/contentful.service";
import { contentType } from "../../common/constants/contentType";
import {useLocation} from "react-router-dom";
import {PagesConstant} from "../../common/constants/pages";

export const SearchBarComponent = ({ searchInfo, performSearch, hotelDataResponse, holidayHeader, filters, holidayListKey, defaultSearchUsed}) => {
  const [hotelActiveTab, setActiveTab] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [contentfulSearchBar, setStateContentfulSearchBar] = useState([{}]);
  const [contentfulButtons, setStateContentfulButtons] = useState([{}]);
  const [contentfulRoomAllocation, setStateContentfulRoomAllocation] = useState([{}]);
  const location = useLocation();
  const isResultsPage = location.pathname === `/app/${PagesConstant.RESULTS}`;
  const contentfulEntries = useContext(ContentfulContext);

    useEffect(() => {
        getSampleHolidaysContent();
    }, [contentfulEntries]);

  const getSampleHolidaysContent = () => {
    const contentfulSearchBarResponse = getContentfulByContentType(
      contentfulEntries,
      contentType.SEARCH_BAR
    );
    setStateContentfulSearchBar(contentfulSearchBarResponse);

    const contentfulButtonsResponse = getContentfulByContentType(
      contentfulEntries,
      contentType.BUTTONS
    );
    setStateContentfulButtons(contentfulButtonsResponse);

    const contentfulRoomAllocationResponse = getContentfulByContentType(
      contentfulEntries,
      contentType.ROOM_ALLOCATION
    );
    setStateContentfulRoomAllocation(contentfulRoomAllocationResponse);
  };

    useEffect(() => {
        window.screen.width < breakpoints.SM
            ? setIsMobile(true)
            : setIsMobile(false);
    }, [window.screen.width]);

    function detectWindowSize() {
        window.screen.width < breakpoints.SM
            ? setIsMobile(true)
            : setIsMobile(false);
    }

    window.onresize = detectWindowSize;

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

  const searchBarComponentElements = (
    <>
      {isResultsPage && <div className="search-mode-switch">
        <div
          className={
            hotelActiveTab
              ? "search-mode-switch active"
              : "search-mode-switch inactive"
          }
          onClick={() => handleTabClick(true)}
        >
          {contentfulSearchBar && contentfulSearchBar[0]?.fields?.flightHotel}
        </div>
        <div
          className={
            !hotelActiveTab
              ? "search-mode-switch active"
              : "search-mode-switch inactive"
          }
          onClick={() => handleTabClick(false)}
        >
          {contentfulSearchBar && contentfulSearchBar[0]?.fields?.flightCar}
        </div>
      </div>}
      {
        contentfulRoomAllocation
          ? (
            <SearchBarContentComponent
              searchInfo={searchInfo}
              contentfulSearchBar={contentfulSearchBar && contentfulSearchBar[0]?.fields}
              contentfulButtons={contentfulButtons && contentfulButtons[0]?.fields}
              contentfulRoomAllocation={contentfulRoomAllocation && contentfulRoomAllocation[0]?.fields}
              hotelActiveTab={hotelActiveTab}
              isMobile={isMobile}
              performSearch={performSearch}
              isResultsPage={isResultsPage}
              searchBarInfo={hotelDataResponse?.data?.holidayAlternatives?.searchBarInfo}
              holidayHeader={holidayHeader}
              filters={filters}
              holidayListKey={holidayListKey}
              defaultSearchUsed={defaultSearchUsed}
            />
          )
          : null
      }
    </>
  );

  return (
    <div className="search-bar-component">
      {
            searchBarComponentElements
      }
    </div>
  );
};

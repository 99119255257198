import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.styles.scss";
import "./regular-datepicker-component.scss";
import "./price-datepicker-component.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LocalStorageService } from "../../services/local-storage.service";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import config from "./../../environments/config.json";
import { useSearchParams } from "react-router-dom";
import { searchParamsEnum } from "../../common/enums/searchParamsEnum";
import { formatDateToYMD } from "../../services/general.service";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";
import DatepickerWrapperComponent from "./datepicker-wrapper/datepicker-wrapper-component";
import {
  formatSelectedDatesForRequest,
  formatSelectedDates,
  minAndMaxDateCalendarHelper,
} from "../../services/date-formatter.service";
import { isSameDay, parseISO } from "date-fns";


const DatepickerComponent = ({
  searchDates,
  searchInfo,
  contentfulButtons,
  contentfulSearchBar,
  onClose,
  isSearchPending,
  setIsSearchPending,
  showPriceCalendarFunction,
  holidayListKey,
  defaultSearchUsed,
  filters,
  setPriceCalendarSelected
}) => {
  const componentRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [departureDateFlexibility, setDepartureDateFlexibility] = useState(0);
  const [isDateRangeValid, setDateRangeValidation] = useState(false);
  const [selectedDates, setSelectedDates] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRegularCalendarSelected, setIsRegularCalendar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const currentDate = new Date();
  const [priceCalendarDuration, setDurationPriceCalendar] = useState();
  const [priceCalendarPreselected, setPriceCalendarPreselected] = useState(false)
  const [previousStartDate, setPreviousStartDate] = useState(null);
  const [isSameDate, setSameDate] = useState(null);
  const [hoverEndDate, setHoverEndDate] = useState(null);
  const [minDateOfAll, maxDateOfAll] = minAndMaxDateCalendarHelper(
    startDate,
    endDate,
    currentDate
  );
  const culture = LocalStorageService.getCulture();
  const windowSizeDetector = useWindowSizeDetector();

  useEffect(() => {
    if (windowSizeDetector.width < breakpoints.SM) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSizeDetector]);

  useEffect(() => {
    componentRef?.current?.setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const savedDates = JSON.parse(
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES)
    );
    let tempSelectedDates;
    setDurationPriceCalendar(searchDates?.durationInDays)

    if (savedDates) {
      tempSelectedDates = {
        departureDate: new Date(savedDates?.departureDate),
        returnDate: new Date(savedDates?.returnDate),
        departureDateFlexibility: savedDates?.departureDateFlexibility,
      };
    } else if (searchDates) {
      tempSelectedDates = {
        departureDate: new Date(searchDates?.departureDate),
        returnDate: new Date(searchDates?.returnDate),
        departureDateFlexibility: searchDates?.departureDateFlexibility,
      };
    } else {
      tempSelectedDates = {
        departureDate: new Date(
          searchParams.get(searchParamsEnum.departureDate)
        ),
        returnDate: new Date(searchParams.get(searchParamsEnum.returnDate)),
        departureDateFlexibility: searchParams.get(
          searchParamsEnum.departureDateFlexibility
        ),
      };
    }
    setSelectedDates(tempSelectedDates);
    setStartDate(tempSelectedDates?.departureDate);
    setEndDate(tempSelectedDates?.returnDate);
    setDepartureDateFlexibility(tempSelectedDates?.departureDateFlexibility);
  }, [
    searchDates,
    LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES),
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      setDateRangeValidation(true);
    } else {
      setDateRangeValidation(false);
    }
  }, [startDate, endDate]);

  const saveChanges = () => {
    const selectedDates = {
      departureDate: startDate,
      returnDate: endDate,
      departureDateFlexibility: departureDateFlexibility,
    };

    if (isDateRangeValid) {
      let newStartDate = formatSelectedDatesForRequest(startDate);
      let newEndDate = formatSelectedDatesForRequest(endDate);

      setIsSearchPending(true);
      setSelectedDates(selectedDates);
      setStartDate(new Date(startDate));
      setEndDate(new Date(endDate));
      setDepartureDateFlexibility(departureDateFlexibility);
      setSearchParams((params) => {
        params.set(searchParamsEnum.departureDate, newStartDate);
        params.set(searchParamsEnum.returnDate, newEndDate);
        params.set(
          searchParamsEnum.departureDateFlex,
          departureDateFlexibility
        );
        return params;
      });

      LocalStorageService.setLocalStorageItem(LocalStorageEnum.SELECTED_DATES, {
        departureDate: newStartDate,
        returnDate: newEndDate,
        departureDateFlexibility: departureDateFlexibility,
      });
      onClose();
    }
  };

  const hasDatesChanged = useCallback(() => {
    const storedDates = JSON.parse(
      LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES)
    );

    if (!storedDates) {
      return true;
    }

    const { departureDate, returnDate } = storedDates;
    const formattedStartDate = startDate ? formatDateToYMD(startDate) : null;
    const formattedEndDate = endDate ? formatDateToYMD(endDate) : null;
    const isDepartureDateSame = formattedStartDate === departureDate;
    const isReturnDateSame = formattedEndDate === returnDate;
    const isFlexibilitySame =
      departureDateFlexibility === storedDates?.departureDateFlexibility;
    return !isDepartureDateSame || !isReturnDateSame || !isFlexibilitySame;
  }, [startDate, endDate, departureDateFlexibility]);

  const handleClick = () => {
    if (isDateRangeValid && hasDatesChanged()) {
      saveChanges();
    } else {
      const previouslySelectedDates = JSON.parse(
        LocalStorageService.getLocalStorageItem(LocalStorageEnum.SELECTED_DATES)
      );
      setStartDate(new Date(previouslySelectedDates?.departureDate));
      setEndDate(new Date(previouslySelectedDates?.returnDate));
      setDepartureDateFlexibility(
        previouslySelectedDates?.departureDateFlexibility
      );
    }
    toggleDatePickerOpen();
  };

  const handleTravelFlexibility = (value) => {
    setDepartureDateFlexibility(value);
  };

  const onChange = (date) => {
    const [start, end] = date;
    const startDateToCompare = new Date(start);

    if (isRegularCalendarSelected || !showPriceCalendarFunction) {
      const endDateToCompare = new Date(end);
      if (startDateToCompare.getTime() === endDateToCompare.getTime()) {
        setStartDate(null);
        return;
      }

      setStartDate(start);
      setEndDate(end);
      const minimumEndDate = new Date(start);
      minimumEndDate.setDate(
        minimumEndDate.getDate() + config.MIN_TRIP_DURATION
      );
      if (minimumEndDate > end) {
        setEndDate(null);
      }
      const maximumEndDate = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate() + config.MAX_TRIP_DURATION
      );
      if (maximumEndDate <= end) {
        setEndDate(null);
      }
    }
    else {
      setDepartureDateFlexibility(0);
      setPriceCalendarPreselected(true);
      if(!isRegularCalendarSelected) {
        setPriceCalendarSelected(true);
      } else {
        setPriceCalendarSelected(false);
      }
      if(isSameDay(start, previousStartDate)) {
        setSameDate(true);
        setPreviousStartDate(null);
      } else {
        setPreviousStartDate(start);
        setStartDate(parseISO(hoverEndDate.departureDate));
        setEndDate(parseISO(hoverEndDate.returnDate));
        setSameDate(false);
      }
    }
  };

  const toggleDatePickerOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DatepickerWrapperComponent
      contentfulButtons={contentfulButtons}
      contentfulSearchBar={contentfulSearchBar}
      formatSelectedDates={formatSelectedDates}
      isSearchPending={isSearchPending}
      setIsSearchPending={setIsSearchPending}
      showPriceCalendarFunction={showPriceCalendarFunction}
      isMobile={isMobile}
      minDateOfAll={minDateOfAll}
      maxDateOfAll={maxDateOfAll}
      culture={culture}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      selectedDates={selectedDates}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      departureDateFlexibility={departureDateFlexibility}
      onChange={onChange}
      handleClick={handleClick}
      toggleDatePickerOpen={toggleDatePickerOpen}
      handleTravelFlexibility={handleTravelFlexibility}
      isDateRangeValid={isDateRangeValid}
      isRegularCalendar={isRegularCalendarSelected}
      setIsRegularCalendar={setIsRegularCalendar}
      holidayListKey={holidayListKey}
      filters={filters}
      setDurationPriceCalendar={setDurationPriceCalendar}
      priceCalendarDuration={priceCalendarDuration}
      priceCalendarPreselected={priceCalendarPreselected}
      isSameDate={isSameDate}
      defaultSearchUsed={defaultSearchUsed}
      hoverEndDate={hoverEndDate}
      setHoverEndDate={setHoverEndDate}
      searchInfo={searchInfo}
    />
  );
};

export default DatepickerComponent;

import { CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { GetApiUrl } from "../../services/general.service";
import { flight } from "../../common/constants/flight";
import { affiliates } from "../../common/enums/affiliates";
import { GetAffiliate, GetLogoPath, removeYearFromDate } from "../../services/general.service";
import FlightDetails from "../flight-details/flight-details";
import { GenericButton } from "../generic-button/generic-button";
import { Relay42Service } from "../../services/analytics/relay42/relay42.service";
import "./holiday-package-flight.component.scss";
import { HolidayPackageFlightFiltersComponent } from "../holiday-package-flight-filters/holiday-package-flight-filters";

export const HolidayPackageFlightComponent = ({
  contentfulHolidayPage,
  contentfulButtons,
  contentfulFilters,
  holidayKey,
  flightOptions,
  holidayHeader,
  performUpdateFlightOption,
  holidayAirlineName,
  setStepperClick,
  flightOptionsFilterInfo
}) => {
  const [textToDisplay, setStateTextToDisplay] = useState(null);
  const [apiFlightOptions, setStateApiFlightOptions] = useState(null);
  const [flightOptionsToDisplay, setStateFlightOptionsToDisplay] =
    useState(null);
  const [displayAllFlightOptions, setStateDisplayAllFlightOptions] =
    useState(false);
  const [selectedFlightOption, setSelectedFlightOption] = useState(null);
  const [fligtDetailsSpace, setFlightDetailsSpace] = useState(false);
  const [updatingFlightOptionId, setUpdatingFlightOptionId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [apiFilterInfo, setStateApiFilterInfo] = useState(null);
  const [showFlightOptionsLoader, setStateShowFlightOptionsLoader] = useState(false);
  const apiHolidayKey = useRef(null);
  const holidayKeyStore = useSelector((state) => state.holidayData.holidayKey);

  useEffect(() => {
    if (holidayKey) {
      apiHolidayKey.current = holidayKey;
    }
  }, [holidayKey]);

  useEffect(() => {
    if (contentfulHolidayPage) {
      setStateTextToDisplay(contentfulHolidayPage[0]?.fields?.flight);
    }
  }, [contentfulHolidayPage]);

  useEffect(() => {
    if (flightOptions) {
      setStateApiFlightOptions(flightOptions);
      setStateFlightOptionsToDisplay(
        [...flightOptions]
          .slice(0, flight.numberOfItemsToDisplay)
          .map((flightOption) => {
            return setFlightOptionProperties(flightOption);
          })
      );
    }
  }, [flightOptions]);

  useEffect(() => {
    if (flightOptionsFilterInfo) {
      setStateApiFilterInfo(flightOptionsFilterInfo);
    }
  }, [flightOptionsFilterInfo]);

  useEffect(() => {
    if (apiFlightOptions) {
      setFlightOptionsListView(apiFlightOptions);
    }
  }, [displayAllFlightOptions]);

  const setFlightOptionProperties = (flightOption) => {
    const flightStopsDetails = {
      flightType: null,
      flightStops: null,
    };

    flightOption.outboundLeg.flightStopsDetails = Object.assign(
      {},
      flightStopsDetails
    );
    flightOption.inboundLeg.flightStopsDetails = Object.assign(
      {},
      flightStopsDetails
    );

    if (flightOption.outboundLeg.numberOStops === 1) {
      flightOption.outboundLeg.flightStopsDetails.flightType =
        flight.type.oneTechnicalStop;
      flightOption.outboundLeg.flightStopsDetails.flightStops =
        flightOption.outboundLeg.numberOStops;
    } else if (flightOption.outboundLeg.numberOStops > 1) {
      flightOption.outboundLeg.flightStopsDetails.flightType =
        flight.type.multipleTechnicalStops;
      flightOption.outboundLeg.flightStopsDetails.flightStops =
        flightOption.outboundLeg.numberOStops;
    } else if (flightOption.outboundLeg.directFlight) {
      flightOption.outboundLeg.flightStopsDetails.flightType =
        flight.type.directFlight;
      flightOption.outboundLeg.flightStopsDetails.flightStops = null;
    } else if (flightOption.outboundLeg.transferAirports.length === 1) {
      flightOption.outboundLeg.flightStopsDetails.flightType =
        flight.type.oneTransfer;
      flightOption.outboundLeg.flightStopsDetails.flightStops =
        flightOption.outboundLeg.transferAirports.length;
    } else if (flightOption.outboundLeg.transferAirports.length > 1) {
      flightOption.outboundLeg.flightStopsDetails.flightType =
        flight.type.multipleTransfers;
      flightOption.outboundLeg.flightStopsDetails.flightStops =
        flightOption.outboundLeg.transferAirports.length;
    }

    if (flightOption.inboundLeg.numberOStops === 1) {
      flightOption.inboundLeg.flightStopsDetails.flightType =
        flight.type.oneTechnicalStop;
      flightOption.inboundLeg.flightStopsDetails.flightStops =
        flightOption.inboundLeg.numberOStops;
    } else if (flightOption.inboundLeg.numberOStops > 1) {
      flightOption.inboundLeg.flightStopsDetails.flightType =
        flight.type.multipleTechnicalStops;
      flightOption.inboundLeg.flightStopsDetails.flightStops =
        flightOption.inboundLeg.numberOStops;
    } else if (flightOption.inboundLeg.directFlight) {
      flightOption.inboundLeg.flightStopsDetails.flightType =
        flight.type.directFlight;
      flightOption.inboundLeg.flightStopsDetails.flightStops = null;
    } else if (flightOption.inboundLeg.transferAirports.length === 1) {
      flightOption.inboundLeg.flightStopsDetails.flightType =
        flight.type.oneTransfer;
      flightOption.inboundLeg.flightStopsDetails.flightStops =
        flightOption.inboundLeg.transferAirports.length;
    } else if (flightOption.inboundLeg.transferAirports.length > 1) {
      flightOption.inboundLeg.flightStopsDetails.flightType =
        flight.type.multipleTransfers;
      flightOption.inboundLeg.flightStopsDetails.flightStops =
        flightOption.inboundLeg.transferAirports.length;
    }

    flightOption.showDetails = false;
    flightOption.outboundLeg.legType = flight.legType.outbound;
    flightOption.inboundLeg.legType = flight.legType.inbound;
    flightOption.operatorToDisplay =
      parseFloat(flightOption.pricePerPerson) >= 0 ? "+" : "-";
    flightOption.priceToDisplay =
      parseFloat(flightOption.pricePerPerson) >= 0
        ? flightOption.pricePerPerson
        : flightOption.pricePerPerson.replace("-", "");

    return flightOption;
  };

  const toggleFlightOptionView = (flightOption) => {
    const output = [...flightOptionsToDisplay];
    const optionToUpdate = output.find(
      (option) =>
        option.flightOptionIdentifier === flightOption.flightOptionIdentifier
    );
    optionToUpdate.showDetails = !optionToUpdate.showDetails;
    setStateFlightOptionsToDisplay(output);
    setFlightDetailsSpace(!fligtDetailsSpace);
    if (selectedFlightOption === flightOption) {
      setSelectedFlightOption(null);
    } else {
      setSelectedFlightOption(flightOption);
    }
  };

  const toggleFlightOptionsListView = () => {
    setStateDisplayAllFlightOptions(!displayAllFlightOptions);
  };

  const setFlightOptionsListView = (flightOptions) => {
    if (!displayAllFlightOptions) {
      setStateFlightOptionsToDisplay(
        [...flightOptions]
          .slice(0, flight.numberOfItemsToDisplay)
          .map((flightOption) => {
            const output = setFlightOptionProperties(flightOption);
            output.showDetails = false;
            return output;
          })
      );
    } else {
      setStateFlightOptionsToDisplay(
        [...flightOptions].map((flightOption) => {
          const output = setFlightOptionProperties(flightOption);
          output.showDetails = false;
          return output;
        })
      );
    }
  }

  const updateFlightOption = async (flightOptionIdentifier) => {
    setIsUpdating(true);
    setUpdatingFlightOptionId(flightOptionIdentifier);
    if ((holidayKeyStore || apiHolidayKey.current) && flightOptionIdentifier) {
      const updateFlightOptionRequestBody = {
        holidayKey: holidayKeyStore ?? apiHolidayKey.current,
        flightOptionIdentifier: flightOptionIdentifier,
      };

      await performUpdateFlightOption(updateFlightOptionRequestBody);

      Relay42Service.customEventTrigger(
        Relay42Service.relay42constans.apiEvents.changeFlight, 
        `/${updateFlightOptionRequestBody.holidayKey}`
      );
      setStateDisplayAllFlightOptions(false);
    }
    setIsUpdating(false);
    setUpdatingFlightOptionId(null);
  };

  const apiApplyFlightFilters = async (filters) => {
    if (filters && (holidayKeyStore || apiHolidayKey.current)) {
      const flightFiltersRequestBody = {
        holidayKey: holidayKeyStore ?? apiHolidayKey.current,
        ...filters
      };
      try {
        setStateShowFlightOptionsLoader(true);
        const response = await axios.put(`${GetApiUrl()}/Holiday/FilterFlightOptions`, flightFiltersRequestBody);
        setStateApiFilterInfo(response?.data?.flightOptionsInfo?.filterInfo);
        setStateApiFlightOptions(response?.data?.flightOptionsInfo?.flightOptions);
        setFlightOptionsListView(response?.data?.flightOptionsInfo?.flightOptions);
        setStateShowFlightOptionsLoader(false);
      } catch (error) {
        console.error("Error filtering flight options:", error);
      }
    }
  };

  const contentHeader = (
    <div className="content-header">
      {textToDisplay ? textToDisplay.headers.main : "Flight"}
    </div>
  );

  const flightOptionsFilters = (
    <>
      {apiFilterInfo?.showFilters && (
        <div className="flight-options-filters">
          <HolidayPackageFlightFiltersComponent
            contentfulButtons={contentfulButtons}
            contentfulFilters={contentfulFilters}
            apiFilterInfo={apiFilterInfo}
            apiApplyFlightFilters={apiApplyFlightFilters}
          ></HolidayPackageFlightFiltersComponent>
        </div>
      )}
    </>
  );

  const flightOptionsListViewSwitch = (
    <>
      {apiFlightOptions && apiFlightOptions.length > 3 ? (
        <div className="flight-options-list-view-switch">
          <GenericButton
            className="view-switch-button"
            onClick={toggleFlightOptionsListView}
          >
            <div className="text-container">
              {displayAllFlightOptions ? (
                <>
                  {textToDisplay
                    ? textToDisplay.variousTextContent.seeLessFlights
                    : "See less flights"}
                </>
              ) : (
                <>
                  {textToDisplay
                    ? textToDisplay.variousTextContent.seeMoreFlights
                    : "See more flights"}
                </>
              )}
            </div>
            <div className="icon">
              <div
                className={
                  displayAllFlightOptions ? "icon-drop-up" : "icon-drop-down"
                }
              ></div>
            </div>
          </GenericButton>
        </div>
      ) : null}
    </>
  );

  const flightOptionsHeader = (
    <div className="flight-options-header">
      <div className="info">
        <div className="icon-container">
          <div className="icon"></div>
        </div>
        <div className="text">
          {GetAffiliate() === affiliates.klm ? (
            textToDisplay ? (
              textToDisplay.headers.outboundLeg
            ) : (
              "Your outward flight"
            )
          ) : GetAffiliate() === affiliates.transavia ? (
            <>
              {textToDisplay
                ? textToDisplay.headers.outboundLeg
                : "Your outward flight"}{" "}
              {holidayAirlineName.outBound.airlineName}
            </>
          ) : null}
        </div>
      </div>
      <div className="info">
        <div className="icon-container">
          <div className="icon"></div>
        </div>
        <div className="text">
          {GetAffiliate() === affiliates.klm ? (
            textToDisplay ? (
              textToDisplay.headers.inboundLeg
            ) : (
              "Your return flight"
            )
          ) : GetAffiliate() === affiliates.transavia ? (
            <>
              {textToDisplay
                ? textToDisplay.headers.inboundLeg
                : "Your return flight"}{" "}
              {holidayAirlineName.inBound.airlineName}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );

  const flightOptionStatus = (flightOption) => {
    return (
      <div className="flight-option-status">
        {flightOption.selected ? (
          <div className="text">
            {textToDisplay
              ? textToDisplay.variousTextContent.including
              : "Including"}
          </div>
        ) : (
          <div className="text">
            <span>{flightOption.operatorToDisplay}</span>
            <span>
              {textToDisplay ? textToDisplay.priceInfo.currency : "€"}
            </span>
            <span>{flightOption.priceToDisplay}</span>
            <span>
              {textToDisplay ? textToDisplay.priceInfo.perPerson : "/ p.p."}
            </span>
          </div>
        )}
      </div>
    );
  };

  const flightOptionSelector = (flightOption) => {
    const isCurrentUpdating =
      updatingFlightOptionId === flightOption.flightOptionIdentifier;

    return (
      <div className="flight-option-selector">
        {flightOption.selected ? (
          <>
            <div className="icon-container">
              <div className="icon"></div>
            </div>
            <div className="text">
              {textToDisplay && textToDisplay.variousTextContent.selected}
            </div>
          </>
        ) : (
          <div className="button-flight-wrapper">
            <GenericButton
              className={`button-select-flight-option ${
                isCurrentUpdating ? "loading" : ""
              }`}
              onClick={() => {
                if (!isUpdating) {
                  updateFlightOption(flightOption.flightOptionIdentifier);
                }
              }}
              disabled={isUpdating}
            >
              {isCurrentUpdating  ? (
                <div className="loading-flight-container">
                  <CircularProgress
                    className="button-loading-spinner-flight"
                    size={7}
                    color="inherit"
                  />
                  <span className="loading-text">
                    {contentfulButtons && contentfulButtons[0]?.fields?.loadingState}
                  </span>
                </div>
              ) : (
                textToDisplay && textToDisplay.variousTextContent.select
              )}
            </GenericButton>
          </div>
        )}
      </div>
    );
  };

  const flightOptionDetailViewSwitch = (flightOption) => {
    return (
      <div
        className="flight-option-detail-view-switch"
        onClick={() => toggleFlightOptionView(flightOption)}
      >
        <div className="text-container">
          {textToDisplay
            ? textToDisplay.variousTextContent.flightDetails
            : "Flight details"}
        </div>
        <div className="icon">
          <div
            className={
              flightOption.showDetails ? "icon-drop-up" : "icon-drop-down"
            }
            onClick={() => toggleFlightOptionView(flightOption)}
          ></div>
        </div>
      </div>
    );
  };

  const sectionDataFlightOut = (leg, isWide) => {
    return (
      <div className="section-flight-out">
        {selectedFlightOption && isWide && (
          <FlightDetails
            data={leg}
            isHoliday={true}
            holidayHeader={holidayHeader}
          />
        )}
      </div>
    );
  };

  const sectionDataFlightIn = (leg, isWide) => {
    return (
      <div className="section-flight-in">
        {selectedFlightOption && isWide && (
          <FlightDetails
            data={leg}
            isHoliday={true}
            holidayHeader={holidayHeader}
          />
        )}
      </div>
    );
  };

  const sectionLeg = (leg, isWide) => {
    return (
      <div className={`section-leg ${leg.legType}`}>
        <div className="vertical">
          <div className="row">
            <div className="column">
              <div className="flight-type-graphics">
                <div
                  className={
                    leg.directFlight
                      ? "icon direct-flight"
                      : "icon indirect-flight"
                  }
                ></div>
              </div>
            </div>
            <div className="column">
              <div className="departure-time-date">
                <div className="xyz departure-time">{leg.departureTime}</div>
                <div className="xyz departure-date">
                  {removeYearFromDate(leg.departureDate)}
                </div>
              </div>
              <div className="xyz flight-duration">
                {leg.durationHours}h{leg.durationMinutes}m
              </div>
              <div className="arrival-time-date">
                <div className="arrival-time">{leg.arrivalTime}</div>
                <div className="arrival-date">
                  {removeYearFromDate(leg.arrivalDate)}
                </div>
              </div>
            </div>
            <div className="column">
              <div className="departure-airport">
                {leg.departureAirport}
              </div>
              <div className="flight-stops">
                {leg.flightStopsDetails.flightStops ? (
                  <span className="flight-stops-number">
                    {leg.flightStopsDetails.flightStops}
                  </span>
                ) : null}
                <span className="flight-stops-text">
                  {textToDisplay?.flightType[leg.flightStopsDetails.flightType]}
                </span>
              </div>
              <div className="arrival-airport">{leg.arrivalAiport}</div>
            </div>
            <div className="column">
              {GetAffiliate() === affiliates.klm && 
                <img
                  src={GetLogoPath(leg.airlineCode)}
                  className="airline-logo"
                  alt="Company Logo"
                />
              }
            </div>
          </div>
          <div className="flight-details-section">
            {selectedFlightOption && isWide && (
              <FlightDetails data={leg} isHoliday={true} />
            )}
          </div>
          <div className="row luggage-baggage-information">
            {leg.showCabinLuggageLabel ?
              <div className="hand-luggage">
                <div
                  className={leg.cabinLuggageIncluded  ? "icon hand-luggage-included" : "icon hand-luggage-excluded"}
                ></div>
                <div className="text">
                  <span>
                    <>{textToDisplay && !leg.cabinLuggageIncluded && textToDisplay?.luggage?.excluded}</>
                    <>{textToDisplay && leg.cabinLuggageIncluded && textToDisplay?.luggage?.included}</>
                  </span>
                  <span className="text-extended">
                    {textToDisplay && textToDisplay?.luggage?.cabinLuggage}
                  </span>
                </div>
              </div>
              :
              <div className="hand-luggage">
                <div
                  className={
                    leg.handLuggageIncluded
                      ? "icon hand-luggage-included"
                      : "icon hand-luggage-excluded"
                  }
                ></div>
                <div className="text">
                  <span>
                    {leg.handLuggageIncluded ? (
                      <>
                        {textToDisplay && textToDisplay?.luggage?.included}
                      </>
                    ) : (
                      <>
                        {textToDisplay && textToDisplay?.luggage?.excluded}
                      </>
                    )}
                  </span>
                  <span className="text-extended">
                    {textToDisplay
                      && textToDisplay?.luggage?.handLuggage}
                  </span>
                </div>
              </div>
            }

            <div className="baggage">
              <div
                className={
                  leg.baggageIncluded
                    ? "icon baggage-included"
                    : "icon baggage-excluded"
                }
              ></div>
              <div className="text">
                <span>
                  {leg.baggageIncluded ? (
                    <>
                      {textToDisplay && textToDisplay?.luggage?.included}
                    </>
                  ) : (
                    <>
                      {textToDisplay && textToDisplay?.luggage?.excluded}
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="horizontal">
          <div className="row">
            <div className="column">
              <div className="departure-date">{leg.departureDate}</div>
              <div className="departure-time">{leg.departureTime}</div>
              <div className="departure-airport">
                {leg.departureAirport}
              </div>
            </div>
            <div className="column">
            {GetAffiliate() === affiliates.klm &&
                <img
                  src={GetLogoPath(leg.airlineCode)}
                  className="airline-logo"
                  alt="Company Logo"
                />
              }
              <div className="flight-type-graphics">
                <div
                  className={
                    leg.directFlight
                      ? "icon direct-flight"
                      : "icon indirect-flight"
                  }
                ></div>
              </div>
              <div className="flight-stops">
                {leg.flightStopsDetails.flightStops ? (
                  <span className="flight-stops-number">
                    {leg.flightStopsDetails.flightStops}
                  </span>
                ) : null}
                <span className="flight-stops-text">
                  {textToDisplay?.flightType[leg.flightStopsDetails.flightType]}
                </span>
              </div>
              <div className="flight-duration">
                {leg.durationHours}h{leg.durationMinutes}m
              </div>
            </div>
            <div className="column">
              <div className="arrival-date">{leg.arrivalDate}</div>
              <div className="arrival-time">{leg.arrivalTime}</div>
              <div className="arrival-airport">{leg.arrivalAiport}</div>
            </div>
          </div>
          <div className="flight-details-section">
            {selectedFlightOption && isWide && (
              <FlightDetails data={leg} isHoliday={true} />
            )}
          </div>
          {/* DESKTOP */}
          <div className="row luggage-baggage-information">
            {leg.showCabinLuggageLabel ?
            <div className="hand-luggage">
              <div className={leg.cabinLuggageIncluded ? "icon hand-luggage-included" 
                    : "icon hand-luggage-excluded"}></div>
              <div className="text">
              <span>
                <>{textToDisplay && !leg.cabinLuggageIncluded && textToDisplay?.luggage?.excluded}</>
                <>{textToDisplay && leg.cabinLuggageIncluded && textToDisplay?.luggage?.included}</>
              </span>
                <span className="text-extended">
                  {textToDisplay && textToDisplay?.luggage?.cabinLuggage }
                </span>
              </div>
            </div>
            :
            <div className="hand-luggage">
              <div
                className={
                  leg.handLuggageIncluded
                    ? "icon hand-luggage-included"
                    : "icon hand-luggage-excluded"
                }
              ></div>
              <div className="text">
                <span>
                  {leg.handLuggageIncluded ? (
                    <>
                      {textToDisplay && textToDisplay?.luggage?.included}
                    </>
                  ) : (
                    <>
                      {textToDisplay && textToDisplay?.luggage?.excluded}
                    </>
                  )}
                </span>
                <span className="text-extended">
                  {textToDisplay && textToDisplay?.luggage?.handLuggage}
                </span>
              </div>
            </div>
            }

            <div className="baggage">
              <div
                className={
                  leg.baggageIncluded
                    ? "icon baggage-included"
                    : "icon baggage-excluded"
                }
              ></div>
              <div className="text">
                <span>
                  {leg.baggageIncluded ? (
                    <>
                      {textToDisplay && textToDisplay?.luggage?.included}
                    </>
                  ) : (
                    <>
                      {textToDisplay && textToDisplay?.luggage?.excluded}
                    </>
                  )}
                </span>
                <span className="text-extended">
                  {textToDisplay
                    ? textToDisplay?.luggage?.baggage
                    : "check-in baggage"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const sectionBorderline = () => {
    return <div className="section-borderline"></div>;
  };

  const flightOptionDetails = (flightOption, isWide) => {
    return (
      <div className="flight-option-details">
        {sectionLeg(flightOption.outboundLeg, isWide)}
        {sectionBorderline()}
        {sectionLeg(flightOption.inboundLeg, isWide)}
      </div>
    );
  };

  const flightOptionsContent = (
    <div className="flight-options-content">
      {flightOptionsToDisplay?.map((flightOption, index) => {
        const isWide =
          selectedFlightOption?.flightOptionIdentifier ===
          flightOption.flightOptionIdentifier;
        const flightOptionClasses = `flight-option ${
          flightOption.selected ? "selected" : ""
        } ${isWide ? "wide" : ""} ${
          isUpdating && flightOption.flightOptionIdentifier !== updatingFlightOptionId
            ? "opacity-50"
            : ""
        }`;

        return (
          <div
            className={flightOptionClasses}
            key={flightOption.flightOptionIdentifier}
          >
            {flightOptionStatus(flightOption)}
            {flightOptionSelector(flightOption)}
            {flightOptionDetails(flightOption, isWide)}
            {sectionDataFlightOut(flightOption.outboundLeg, isWide)}
            {sectionDataFlightIn(flightOption.inboundLeg, isWide)}
            {flightOptionDetailViewSwitch(flightOption)}
          </div>
        );
      })}
    </div>
  );

  const flightOptionsContainer = (
    <div className="flight-options-container">
      {flightOptionsHeader}
      {flightOptionsContent}
    </div>
  );

  const flightOptionsLoader = (
    <div className="flight-options-loader">
      <div className="loading-wrapper">
        <section className="spinner-container">
          <CircularProgress />
          <p className="spinner-text">
            {contentfulFilters && contentfulFilters[0]?.fields?.flightFiltersLoaderText}
          </p>
        </section>
      </div>
    </div>
  );

  const componentElements = (
    <>
      <div className="holiday-package-flight-component">
        <div className="content">
          {contentHeader}
          {flightOptionsFilters}
          {
            showFlightOptionsLoader
              ? <>{flightOptionsLoader}</>
              : <>
                {flightOptionsContainer}
                {flightOptionsListViewSwitch}
              </>
          }
        </div>
      </div>
    </>
  );

  return apiFlightOptions ? componentElements : null;
};

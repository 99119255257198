import { useEffect, useState } from "react";
import { accommodationOptionsEnum } from "../../common/enums/accommodationOptions";
import { AccommodationOptionCard } from "../accommodation-option-card/accommodation-option.card.component";
import { AccommodationOptionGroup } from "../accommodation-option-group/accommodation-option-group.component";
import { GenericButton } from "../generic-button/generic-button";

import "./accommodation-component.component.scss";

export const AccommodationComponent = ({
  accommodationRoomOptionsInfo,
  contentfulButtons,
  contentfulAccommodationSection,
  contentfulAccommodationShowDetails,
  authInfo,
  performRoomOptionSelection,
  isLoading,
}) => {
  const [moreRoomOptions, toggleMoreRoomOptions] = useState(false);
  const [isMultiRoom, setIsMultiRoom] = useState();

  useEffect(() => {
    setIsMultiRoom(accommodationRoomOptionsInfo?.numberOfRooms > 1);
  }, [accommodationRoomOptionsInfo]);

  const displayNumberRoomOptions = () => {
    if (moreRoomOptions) {
      return accommodationRoomOptionsInfo?.roomGroupOptions?.length;
    }
    return accommodationOptionsEnum.defaultNumberOfOptions;
  };

  const handleOnExpandButtonClick = () => {
    toggleMoreRoomOptions(!moreRoomOptions);
  };

  const handleRoomOptionSelect = () => {
    toggleMoreRoomOptions(false);
  };

  return (
    <section className="accommodation-component">
      <div className="accommodation-component__header">
        {contentfulAccommodationSection?.accommodation}
      </div>
      {accommodationRoomOptionsInfo?.roomGroupOptions && isMultiRoom ? (
        accommodationRoomOptionsInfo?.roomGroupOptions.map(
          (option, index) =>
            displayNumberRoomOptions() > index && (
              <AccommodationOptionGroup
                key={`room-group-option-${index}`}
                contentfulButtons={contentfulButtons}
                contentfulAccommodationSection={contentfulAccommodationSection}
                numberOfRooms={accommodationRoomOptionsInfo?.numberOfRooms}
                numberOfTravelers={
                  accommodationRoomOptionsInfo?.numberOfTravelers
                }
                roomOption={option}
                optionGroupIndex={index}
                accommodationOptions={option?.rooms}
                contentfulAccommodationShowDetails={
                  contentfulAccommodationShowDetails
                }
                authInfo={authInfo}
                performRoomOptionSelection={performRoomOptionSelection}
                isLoading={isLoading}
                onSelect={handleRoomOptionSelect}
              />
            )
        )
      ) : (
        <>
          <div className="accommodation-component__subheader">
            <span className="subheader__room-type">
              {contentfulAccommodationSection?.roomType}
            </span>
            <span className="subheader__room-details">
              {accommodationRoomOptionsInfo?.numberOfRooms}{" "}
              {contentfulAccommodationSection?.room}
              {", "}
              {accommodationRoomOptionsInfo?.numberOfTravelers}{" "}
              {contentfulAccommodationSection?.travellers}
            </span>
          </div>
          {accommodationRoomOptionsInfo?.roomGroupOptions.map(
            (option, index) =>
              displayNumberRoomOptions() > index && (
                <AccommodationOptionCard
                  key={`room-single-option-${index}`}
                  accommodationOptions={option.rooms}
                  contentfulAccommodationSection={
                    contentfulAccommodationSection
                  }
                  authInfo={authInfo}
                  contentfulAccommodationShowDetails={
                    contentfulAccommodationShowDetails
                  }
                  roomOption={option}
                  performRoomOptionSelection={performRoomOptionSelection}
                  contentfulButtons={contentfulButtons}
                  numberOfRooms={accommodationRoomOptionsInfo?.numberOfRooms}
                  showAsSingleRoom={
                    accommodationRoomOptionsInfo?.showAsSingleRoom
                  }
                  isLoading={isLoading}
                  onSelect={handleRoomOptionSelect}
                />
              )
          )}
        </>
      )}
      {accommodationRoomOptionsInfo?.roomGroupOptions?.length >
      accommodationOptionsEnum.defaultNumberOfOptions ? (
        <GenericButton
          className="more-accommodation-button"
          onClick={handleOnExpandButtonClick}
        >
          {moreRoomOptions && (
            <div className="button-container">
              <div>
                {contentfulButtons && isMultiRoom
                  ? contentfulButtons[0]?.fields?.showLessRoomTypeOptions
                  : contentfulButtons[0]?.fields?.showLessRooms}
              </div>
              <div className="dropdown-caret rotate"></div>
            </div>
          )}
          {!moreRoomOptions && (
            <div className="button-container">
              {contentfulButtons && isMultiRoom
                ? contentfulButtons[0]?.fields?.showAllRoomTypeOptions
                : contentfulButtons[0]?.fields?.showAllRooms}
              <div className="dropdown-caret"></div>
            </div>
          )}
        </GenericButton>
      ) : (
        <div className="spacer-sm"></div>
      )}
    </section>
  );
};

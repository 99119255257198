import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { htmlTagNames } from "../../common/enums/htmlTagNames";
import { AccommodationOptionCard } from "../accommodation-option-card/accommodation-option.card.component";
import { AccommodationSelectButton } from "../accommodation-select-button/accommodation-select-button.component";
import "./accommodation-option-group.component.scss";

export const AccommodationOptionGroup = ({
  contentfulButtons,
  contentfulAccommodationSection,
  roomOption,
  numberOfRooms,
  numberOfTravelers,
  accommodationOptions,
  contentfulAccommodationShowDetails,
  authInfo,
  performRoomOptionSelection,
  isLoading,
  onSelect
}) => {
  const [isSelected, setIsSelected] = useState();
  const [isBeingSelected, setIsBeingSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(roomOption.selected);

  useEffect(() => {
    if (roomOption) {
      setIsSelected(roomOption?.selected);
      setIsBeingSelected(false);
    }
  }, [roomOption]);

  const getAccordionIcon = () => {
    let IconComponent;
    if (isOpen) {
      IconComponent = ExpandLess;
    } else {
      IconComponent = ExpandMore;
    }

    return <IconComponent className="status__accordion-icon" />;
  };

  const handleSectionRoomOption = (e) => {
    if (e.target.tagName !== htmlTagNames.button) {
      setIsOpen(!isOpen);
    }
    if (isLoading) {
      e.preventDefault();
    }
  };

  const calculateTotalPricePerPassenger = (accommodationOptions) => {
    return accommodationOptions.reduce((totalPrice, room) => {
      const price = Number(room.price || 0);
      return totalPrice + price;
    }, 0);
  };
  const totalPricePerPassenger =
    calculateTotalPricePerPassenger(accommodationOptions);

  const onSelectSectionRoomOption = async (sectionRoomOptionKey) => {
    setIsBeingSelected(true);
    if (!isLoading) {
      await performRoomOptionSelection(sectionRoomOptionKey);
      onSelect();
    }
  };

  return (
    <div
      className={`accommodation-option-group ${
        isLoading && !isBeingSelected ? "option-group-loading" : ""
      }`}
    >
      <div className="accommodation-option-group__header">
        <div
          className="accommodation-option__selected-info"
          onClick={(e) => handleSectionRoomOption(e)}
        >
          <div className="accommodation-option__room-info">
            <img
              src={roomOption.rooms[0].images[0]}
              alt="Room preview"
              className="room-info__room-image"
            />
            <div className="room-info__room-description">
              <div className="room-description__room-type-wrapper">
                <span className="room-description__room-type">
                  {roomOption?.roomGroupDescriptions?.map(
                    (description, index) => (
                      <div key={index}>{description?.toLowerCase()}</div>
                    )
                  )}
                </span>
                {roomOption?.checkDifferenceInRooms &&
                  `(${contentfulAccommodationSection?.roomTypeDifference})`}
              </div>
              <span className="room-description__room-details">
                ({numberOfRooms} {contentfulAccommodationSection?.rooms}
                {", "}
                {numberOfTravelers} {contentfulAccommodationSection?.travellers}
                )
              </span>
            </div>
          </div>

          <div className="accommodation-option-group__price-selected-btn">
            <div className="accommodation-option-group__price">
              {numberOfRooms > 1 && !isSelected && (
                <div className="additional-cost">
                  {totalPricePerPassenger >= 0 && (
                    <span className="price-sign">+</span>
                  )}{" "}
                  € {totalPricePerPassenger}{" "}
                  <span className="price-per-person">
                    {contentfulAccommodationSection &&
                      contentfulAccommodationSection.pricePerPerson}
                  </span>
                </div>
              )}
            </div>
            <div className="accommodation-option-group__selected-btn">
              <AccommodationSelectButton
                isSelected={roomOption?.selected}
                contentfulButtons={contentfulButtons}
                roomInfo={roomOption?.roomOptionKey}
                onClick={onSelectSectionRoomOption}
                isLoading={isLoading}
              />
              {getAccordionIcon()}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="accommodation-option-group__body">
          <AccommodationOptionCard
            accommodationOptions={roomOption.rooms}
            contentfulAccommodationSection={contentfulAccommodationSection}
            authInfo={authInfo}
            contentfulAccommodationShowDetails={
              contentfulAccommodationShowDetails
            }
            performRoomOptionSelection={performRoomOptionSelection}
            contentfulButtons={contentfulButtons}
            roomOption={roomOption}
            numberOfRooms={numberOfRooms}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

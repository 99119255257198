import { useEffect, useRef, useState } from "react";
import { SearchBarComponent } from "../search-bar/search-bar.component";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";
import { navigation } from "../../common/constants/navigation";
import { barChart as getBarChartDimensions } from "../../common/constants/barchart";
import "./holiday-package-trip.component.scss";
import { GetAffiliate } from "../../services/general.service";
import { PriceMatrixHeader } from "../price-matrix-header/price-matrix-header";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import {
  changeMonthForPriceMatrix,
  toggleAlternativesMatrix,
} from "../../services/holiday.service";
import { PriceMatrixTable } from "../price-matrix-table/price-matrix-table";
import klmWeekOverviewIcon from "../../assets/icons/klm/klm-week-overview-icon.svg";
import klmMonthOverviewIcon from "../../assets/icons/klm/klm-month-overview-icon.svg";
import transaviaWeekOverviewIcon from "../../assets/icons/transavia/transavia-week-overview-icon.svg";
import transaviaMonthOverviewIcon from "../../assets/icons/transavia/transavia-month-overview-icon.svg";
import { affiliates } from "../../common/enums/affiliates";

export const HolidayPackageTripComponent = ({
  searchInfo,
  performSearch,
  hotelDataResponse,
  holidayAlternatives,
  contentfulHolidayPage,
  contentfulHolidayPriceMatrix,
  performSelectAlternativeOption,
  searchBarRef,
  holidayHeader,
}) => {
  const [textToDisplay, setStateTextToDisplay] = useState(null);
  const [barGraphCollection, setStateBarGraphCollection] = useState(null);
  const [filteredBarGraphCollection, setStateFilteredBarGraphCollection] =
    useState(null);
  const [barChartNavigation, setStateBarChartNavigation] = useState([
    { previous: true, next: true },
  ]);
  const [shouldShowMatrix, setShouldShowMatrix] = useState(false);
  const [barCount, setStateBarCount] = useState(null);
  const [priceMatrixData, setPriceMatrixData] = useState({});
  const [isPriceMatrixLoading, setIsPriceMatrixLoading] = useState(false);
  const holidayKey = useRef(null);
  const buttonRef = useRef();
  const windowSizeDetector = useWindowSizeDetector();

  const affiliate = GetAffiliate();
  const barChartDimensions = getBarChartDimensions(affiliate);

  const buildPriceMatrixInfo = () => {
    setPriceMatrixData(holidayAlternatives?.priceMatrixInfo);
  };

  useEffect(() => {
    const selectedCellKey = localStorage.getItem(LocalStorageEnum.CELL_SELECTED);
    if (selectedCellKey && buttonRef.current) {
      const elementPosition = buttonRef.current.offsetTop; 
      const scrollOffset = -1200
  
      window.scrollTo({
        top: elementPosition - scrollOffset,
        behavior: "smooth",
      });
      localStorage.removeItem(LocalStorageEnum.CELL_SELECTED);
    }
  }, []);
  
  useEffect(() => {
    if (priceMatrixData) {
      setIsPriceMatrixLoading(false);
    }
  }, [priceMatrixData]);

  useEffect(() => {
    if (holidayAlternatives) {
      setBarGraphCollection(holidayAlternatives.alternativeOptions);
      setShouldShowMatrix(holidayAlternatives.showPriceMatrixFirst);
      buildPriceMatrixInfo();
    }
  }, [holidayAlternatives]);

  useEffect(() => {
    if (hotelDataResponse && hotelDataResponse.data) {
      holidayKey.current = hotelDataResponse.data.holidayKey;
    }
  }, [hotelDataResponse]);

  useEffect(() => {
    if (barGraphCollection) {
      setStateBarCount(setBarCount());

      if (barCount) {
        setFilteredBarGraphCollection();
      }
    }
  }, [barGraphCollection, barCount]);

  useEffect(() => {
    if (
      filteredBarGraphCollection &&
      filteredBarGraphCollection.length > 0 &&
      !shouldShowMatrix
    ) {
      const holidayPackageBarChartContent = document.getElementById(
        "holiday-package-bar-chart-content"
      );
      while (holidayPackageBarChartContent.firstChild) {
        holidayPackageBarChartContent.removeChild(
          holidayPackageBarChartContent.lastChild
        );
      }

      filteredBarGraphCollection.forEach((option) => {
        setBarChartElement(option);
      });
    }
  }, [filteredBarGraphCollection, textToDisplay, shouldShowMatrix]);

  useEffect(() => {
    if (contentfulHolidayPage && contentfulHolidayPage.length > 0) {
      setStateTextToDisplay(contentfulHolidayPage[0]?.fields?.trip);
    }
  }, [contentfulHolidayPage]);

  const setBarGraphCollection = (collection) => {
    const output = [...collection];
    const barGraphCollectionSortedAsc = [...output].sort((a, b) =>
      a.priceAsNumber < b.priceAsNumber ? -1 : 1
    );
    const barGraphCollectionMinValue =
      barGraphCollectionSortedAsc[0].priceAsNumber;
    const barGraphCollectionMaxValue =
      barGraphCollectionSortedAsc[barGraphCollectionSortedAsc.length - 1]
        .priceAsNumber;
    const oldRange = barGraphCollectionMaxValue - barGraphCollectionMinValue;
    const newRange =
      barChartDimensions.height.max - barChartDimensions.height.min;

    output.forEach((option, index) => {
      const newPrice =
        ((option.priceAsNumber - barGraphCollectionMinValue) * newRange) /
          oldRange +
        barChartDimensions.height.min;
      const newPricePx = newPrice + "px";
      option.newPricePx = newPricePx;
      option.index = index;
    });

    setStateBarGraphCollection(output);
  };

  const setBarCount = () => {
    let output;

    if (windowSizeDetector.width > breakpoints.LG1100) {
      output = barChartDimensions.size.large;
    } else if (windowSizeDetector.width > breakpoints.MD750) {
      output = barChartDimensions.size.medium;
    } else {
      output = barChartDimensions.size.small;
    }

    if (output >= barGraphCollection.length) {
      output = barGraphCollection.length;
      setStateBarChartNavigation({ previous: false, next: false });
    }

    return output;
  };

  const setFilteredBarGraphCollection = () => {
    if (barGraphCollection) {
      let output;
      const findSelectedIndex = barGraphCollection.findIndex(
        (element) => element.selected
      );

      if (barCount === barChartDimensions.size.large) {
        if (
          barGraphCollection[
            findSelectedIndex - barChartDimensions.range.large
          ] &&
          barGraphCollection[findSelectedIndex + barChartDimensions.range.large]
        ) {
          output = loopBarGraphCollection(
            findSelectedIndex - barChartDimensions.range.large,
            barCount
          );
        } else {
          output = loopBarGraphCollection(0, barCount);
        }
      } else if (barCount === barChartDimensions.size.medium) {
        if (
          barGraphCollection[
            findSelectedIndex - barChartDimensions.range.medium
          ] &&
          barGraphCollection[
            findSelectedIndex + barChartDimensions.range.medium
          ]
        ) {
          output = loopBarGraphCollection(
            findSelectedIndex - barChartDimensions.range.medium,
            barCount
          );
        } else {
          output = loopBarGraphCollection(0, barCount);
        }
      } else if (barCount === barChartDimensions.size.small) {
        if (
          barGraphCollection[
            findSelectedIndex - barChartDimensions.range.small
          ] &&
          barGraphCollection[findSelectedIndex + barChartDimensions.range.small]
        ) {
          output = loopBarGraphCollection(
            findSelectedIndex - barChartDimensions.range.small,
            barCount
          );
        } else {
          output = loopBarGraphCollection(0, barCount);
        }
      }

      setNavigationButtonsState(output);
      setStateFilteredBarGraphCollection(output);
    }
  };

  const loopBarGraphCollection = (startIndex, barCount) => {
    const output = [];
    let counter = 0;
    let index = startIndex < 0 ? 0 : startIndex;

    while (counter < barCount) {
      if (barGraphCollection[index]) {
        output.push(barGraphCollection[index]);
      }
      counter += 1;
      index += 1;
    }
    return output;
  };

  const setBarChartElement = (option) => {
    const barChartElement = document.createElement("div");
    barChartElement.classList.add("bar-chart-element");
    if (option.selected) {
      barChartElement.classList.add("selected");
    }
    barChartElement.onclick = () => onclickBarChartElement(option.index);
    document
      .getElementById("holiday-package-bar-chart-content")
      .appendChild(barChartElement);

    const barSection = document.createElement("div");
    barSection.classList.add("bar-section");
    barSection.setAttribute("style", `height:${option.newPricePx}`);
    barChartElement.appendChild(barSection);

    const elementFrom = document.createElement("div");
    elementFrom.innerText = textToDisplay ? textToDisplay.from : "from";
    barSection.appendChild(elementFrom);

    const elementPrice = document.createElement("div");
    elementPrice.classList.add("price");
    elementPrice.innerText = "€ ";
    elementPrice.innerText += option.price;
    barSection.appendChild(elementPrice);

    const elementPerPerson = document.createElement("div");
    elementPerPerson.innerText = textToDisplay
      ? textToDisplay.perPerson
      : "p.p.";
    barSection.appendChild(elementPerPerson);

    const elementSeparator = document.createElement("div");
    elementSeparator.classList.add("separator");
    barSection.appendChild(elementSeparator);

    const elementDuration = document.createElement("div");
    elementDuration.innerText = option.duration + " " + textToDisplay?.days;
    barSection.appendChild(elementDuration);

    const elementNumberOfNights = document.createElement("div");
    elementNumberOfNights.innerText =
      "(" + option.numberOfNights + " " + textToDisplay?.nights + ")";
    barSection.appendChild(elementNumberOfNights);

    const buttonSection = document.createElement("div");
    buttonSection.classList.add("button-section");
    barChartElement.appendChild(buttonSection);

    const elementText = document.createElement("div");
    const trimmedSubstringLength = 5;
    elementText.innerText = option.departureDate.substring(
      0,
      option.departureDate.length - trimmedSubstringLength
    ); // API response includes year info which is not necessary
    buttonSection.appendChild(elementText);

    const elementCheckboxContainer = document.createElement("div");
    elementCheckboxContainer.classList.add("checkbox-container");
    elementCheckboxContainer.classList.add(
      option.selected ? "active" : "inactive"
    );
    buttonSection.appendChild(elementCheckboxContainer);

    const elementCheckbox = document.createElement("div");
    elementCheckbox.classList.add("checkbox");
    elementCheckboxContainer.appendChild(elementCheckbox);
  };

  const setNavigationButtonsState = (collection) => {
    const previousOptionAvailable =
      collection && collection.length > 0 && collection[0].index > 0
        ? true
        : false;

    const nextOptionAvailable =
      collection &&
      collection.length > 0 &&
      barGraphCollection.length > 0 &&
      collection[collection.length - 1].index <
        barGraphCollection[barGraphCollection.length - 1].index
        ? true
        : false;

    setStateBarChartNavigation({
      previous: previousOptionAvailable,
      next: nextOptionAvailable,
    });
  };

  const navigateFilteredBarGraphCollection = (direction) => {
    if (
      !filteredBarGraphCollection ||
      filteredBarGraphCollection.length === 0 ||
      !barGraphCollection ||
      barGraphCollection.length === 0
    ) {
      return;
    }

    const firstElementIndex = filteredBarGraphCollection[0].index;
    const lastElementIndex =
      filteredBarGraphCollection[filteredBarGraphCollection.length - 1].index;

    if (direction === navigation.previous) {
      if (firstElementIndex > 0) {
        const output = loopBarGraphCollection(firstElementIndex - 1, barCount);
        setNavigationButtonsState(output);
        setStateFilteredBarGraphCollection(output);
      }
    } else if (direction === navigation.next) {
      if (
        lastElementIndex <
        barGraphCollection[barGraphCollection.length - 1].index
      ) {
        const output = loopBarGraphCollection(firstElementIndex + 1, barCount);
        setNavigationButtonsState(output);
        setStateFilteredBarGraphCollection(output);
      }
    }
  };

  const onclickBarChartElement = (index) => {
    const element = barGraphCollection.find(
      (element) => element.index === index
    );
    if (!element.selected && holidayKey.current) {
      const selectAlternativeOptionRequestBody = {
        holidayKey: holidayKey.current,
        holidayOptionKey: element.holidayOptionKey,
      };
      performSelectAlternativeOption(selectAlternativeOptionRequestBody);
    }
  };

  const changeSelectedMonthForPriceMatrix = async (selectedPeriod) => {
    const newMatrixData = await changeMonthForPriceMatrix(
      holidayKey.current,
      selectedPeriod
    );
    setPriceMatrixData(newMatrixData);
  };

  const getModeSwitchButtonIcon = () => {
    if (GetAffiliate() === affiliates.klm) {
      if (shouldShowMatrix) {
        return klmWeekOverviewIcon;
      }
      return klmMonthOverviewIcon;
    } else {
      if (shouldShowMatrix) {
        return transaviaWeekOverviewIcon;
      }
      return transaviaMonthOverviewIcon;
    }
  };

  const togglePriceView = async () => {
    setShouldShowMatrix(!shouldShowMatrix);
    await toggleAlternativesMatrix(holidayKey.current);
  };

  return (
    <div className="holiday-package-trip-component">
      <div className="content">
        <div className="content-header">
          {textToDisplay ? textToDisplay.header : "Trip"}
        </div>
        <div ref={searchBarRef} className="content-searchbar">
          <SearchBarComponent
            searchInfo={searchInfo}
            performSearch={performSearch}
            hotelDataResponse={hotelDataResponse}
            holidayAlternatives={holidayAlternatives}
            holidayHeader={holidayHeader}
            clientKey={holidayKey}
          />
        </div>
        <button
          className="price-overview-mode-switch-button"
          ref={buttonRef}
          onClick={togglePriceView}
        >
          <img
            src={getModeSwitchButtonIcon()}
            alt="Switch price mode button icon"
          />
          {contentfulHolidayPriceMatrix && (
            <span>
              {shouldShowMatrix
                ? contentfulHolidayPriceMatrix[0]?.fields?.weekOverview
                : contentfulHolidayPriceMatrix[0]?.fields?.monthOverview}
            </span>
          )}
        </button>
        {shouldShowMatrix && holidayAlternatives?.priceMatrixInfo && (
          <div className="content-price-matrix-container">
            <PriceMatrixHeader
              holidayKey={holidayKey?.current}
              alternativeOptionsData={holidayAlternatives}
              priceMatrixInfo={priceMatrixData}
              changeSelectedMonthForPriceMatrix={
                changeSelectedMonthForPriceMatrix
              }
              isLoading={isPriceMatrixLoading}
              setIsLoading={setIsPriceMatrixLoading}
            />
            <PriceMatrixTable
              priceMatrixInfo={priceMatrixData}
              contentfulHolidayPriceMatrix={contentfulHolidayPriceMatrix}
              isLoading={isPriceMatrixLoading}
            />
          </div>
        )}
        {!shouldShowMatrix && (
          <div className="content-bar-chart-container">
            <div
              id="holiday-package-bar-chart"
              className="holiday-package-bar-chart"
            >
              <div
                onClick={() =>
                  navigateFilteredBarGraphCollection(navigation.previous)
                }
                className="navigation"
              >
                <div
                  className={
                    barChartNavigation.previous
                      ? "previous"
                      : "previous disabled"
                  }
                >
                  <div className="icon"></div>
                </div>
                <div className="label-mobile">
                  {textToDisplay?.leaveEarlier}
                </div>
              </div>
              <div
                id="holiday-package-bar-chart-content"
                className="content"
              ></div>
              <div
                onClick={() =>
                  navigateFilteredBarGraphCollection(navigation.next)
                }
                className="navigation"
              >
                <div className="label-mobile">{textToDisplay?.leaveLater}</div>
                <div
                  className={barChartNavigation.next ? "next" : "next disabled"}
                >
                  <div className="icon"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import "./datepicker-header-component.scss";
import { DatepickerDropdownComponent } from "./datepicker-dropdown/datepicker-dropdown-component";

const DatepickerHeaderComponent = ({
  setIsRegularCalendar,
  isRegularCalendar,
  contentfulSearchBar,
  priceCalendarData,
  getPriceCalendarData,
  startDate,
  endDate,
  setEndDate,
  setDuration,
  defaultSearchUsed,
  priceCalendarDuration,
  setDurationPriceCalendar,
  hoverEndDate
}) => {
  const handleOnClick = (value) => {
    setIsRegularCalendar(value);
  };

  useEffect(() => {
    if (defaultSearchUsed) {
      setIsRegularCalendar(true);
    }
  }, [defaultSearchUsed, setIsRegularCalendar]);

  return (
    <div
      className={`${
        isRegularCalendar
          ? "regular-datepicker-header"
          : "price-datepicker-header"
      }`}
    >
      {!defaultSearchUsed && (
        <div className="datepicker-header-container">
          <div className="datepicker-header-buttons">
            <button
              className={`regular-datepicker-button ${
                isRegularCalendar ? "active" : ""
              }`}
              onClick={() => handleOnClick(true)}
            >
              {contentfulSearchBar?.regularCalendar}
            </button>
            <button
              className={`price-datepicker-button ${
                !isRegularCalendar ? "active" : ""
              }`}
              onClick={() => handleOnClick(false)}
            >
              {contentfulSearchBar?.priceCalendar}
            </button>
          </div>
        </div>
      )}

      {!isRegularCalendar && (
        <>
          <div className="datepicker-header-input">
            <div className="datpicker-input-duration">
              <DatepickerDropdownComponent
                contentfulSearchBar={contentfulSearchBar}
                priceCalendarData={priceCalendarData}
                getPriceCalendarData={getPriceCalendarData}
                startDate={startDate}
                endDate={endDate}
                setEndDate={setEndDate}
                priceCalendarDuration={priceCalendarDuration}
                setDurationPriceCalendar={setDurationPriceCalendar}
                hoverEndDate={hoverEndDate}
              />
            </div>
          </div>
          <div className="datepicker-header-duration">
            {contentfulSearchBar?.travelPeriod}
          </div>
        </>
      )}
    </div>
  );
};

export default DatepickerHeaderComponent;

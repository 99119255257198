import "./extras-page.component.scss";
import { TransferComponent } from "./transfer/transfers.component";
import { CarComponent } from "./car/car.component";
import { useSelector } from "react-redux";
import axios from "axios";
import { useContext, useEffect, useReducer, useState } from "react";
import { useDispatch as useDispatchShoppingCart } from "react-redux";
import { ReactComponent as BackIconKLM } from "../../assets/icons/klm/back_icon_KLM.svg";
import { ReactComponent as BackIconTransavia } from "../../assets/icons/transavia/back_icon_transavia.svg";
import { contentType } from "../../common/constants/contentType";
import { affiliates } from "../../common/enums/affiliates";
import { contentfulEntriesReducer } from "../../common/enums/contentfulEntriesReducer";
import { PagesStepperBarComponent } from "../../components/pages-stepper-bar/pages-stepper-bar.component";
import { ShoppingCartComponent } from "../../components/shopping-cart/shopping-cart.component";
import { getContentfulByContentType } from "../../services/contentful.service";
import { GetAffiliate, GetApiUrl } from "../../services/general.service";
import ContentfulContext from "../../store/contentful/contentful-context";
import {
  getCheckOutDetailsCart,
  updateBaggageSelection,
  updateCheckOutDataReducer,
} from "../../store/shopping/slice";
import { CabinLuggage } from "./cabin-luggage/cabin-luggage.component";
import "./extras-page.component.scss";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { ancillaryTypes } from "../../common/constants/ancillaryTypes";

const initialState = {
  contentfulButtons: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case contentfulEntriesReducer.CHECKOUT_PASSENGER_DETAILS:
      return { ...state, contentfulCheckoutPassengerDetails: action.payload };
    case contentfulEntriesReducer.BUTTONS:
      return { ...state, contentfulButtons: action.payload };
    case contentfulEntriesReducer.EXTRAS_PAGE:
      return { ...state, contentfulExtrasPage: action.payload };
    case contentfulEntriesReducer.LUGGAGE_SELECTION:
      return { ...state, contentfulLuggageSelection: action.payload };
    case contentfulEntriesReducer.TRANSFERS_COMPONENT:
      return { ...state, contentfulTransfer: action.payload };
    default:
      throw new Error("Unknown data");
  }
};

export const ExtrasPageComponent = ({
  setDeepLinkParams,
  assignCheckOutData,
  showCustomModal,
}) => {
  const [checkOutData, setCheckOutData] = useState(null);
  const contentfulEntries = useContext(ContentfulContext);
  const holidayKey = useSelector((state) => state.holidayData.holidayKey);
  const [isWaitingForNetwork, setIsWaitingForNetwork] = useState(false);
  const [loadingPassengerId, setLoadingPassengerId] = useState(null);
  const dispatchData = useDispatchShoppingCart();
  const [isTransferSelected, setIsTransferSelected] = useState(false);
  const [isCarSelected, setIsCarSelected] = useState(false);
  const [showBaggageComponent, setShowBaggageComponent] = useState(false);
  const [isBaggageValid, setIsBaggageValid] = useState(true);
  const [isValidationInProgress, setIsValidationInProgress] = useState(false);

  const [
    {
      contentfulCheckoutPassengerDetails,
      contentfulButtons,
      contentfulExtrasPage,
      contentfulTransfer,
      contentfulLuggageSelection,
    },
    dispatchReducer,
  ] = useReducer(reducer, initialState);

  const contentfulPassengerDetailsPageResponse = () => {
    const contentfulCheckoutPassengerDetails = getContentfulByContentType(
      contentfulEntries,
      contentType.CHECKOUT_PASSENGER_DETAILS
    );
    const contentfulButtons = getContentfulByContentType(
      contentfulEntries,
      contentType.BUTTONS
    );
    const contentfulExtrasPage = getContentfulByContentType(
      contentfulEntries,
      contentType.EXTRAS_PAGE
    );

    const contentfulLuggageSelection = getContentfulByContentType(
      contentfulEntries,
      contentType.LUGGAGE_SELECTION
    );

    const contentfulTransfer = getContentfulByContentType(
      contentfulEntries,
      contentType.TRANSFERS_COMPONENT
    );

    dispatchReducer({
      type: contentfulEntriesReducer.CHECKOUT_PASSENGER_DETAILS,
      payload: contentfulCheckoutPassengerDetails,
    });
    dispatchReducer({
      type: contentfulEntriesReducer.BUTTONS,
      payload: contentfulButtons,
    });
    dispatchReducer({
      type: contentfulEntriesReducer.EXTRAS_PAGE,
      payload: contentfulExtrasPage,
    });
    dispatchReducer({
      type: contentfulEntriesReducer.LUGGAGE_SELECTION,
      payload: contentfulLuggageSelection,
    });

    dispatchReducer({
      type: contentfulEntriesReducer.TRANSFERS_COMPONENT,
      payload: contentfulTransfer,
    });
  };

  useEffect(() => {
    contentfulPassengerDetailsPageResponse();
  }, [contentfulEntries]);

  const getCheckOutDetails = async () => {
    setIsWaitingForNetwork(true);
    const checkOutDetailsResponse = await dispatchData(
      getCheckOutDetailsCart(holidayKey)
    );
    setIsWaitingForNetwork(false);
    setCheckOutData(checkOutDetailsResponse.payload);
    setDeepLinkParams(
      checkOutDetailsResponse?.payload?.metaInfo?.navigateToHoliday
    );

    AnalyticsService.handleEvents([
      {
        platform: AnalyticsService.analyticsConstants.platform.gtmRelay42,
        type: AnalyticsService.analyticsConstants.eventType.pageView,
        name: AnalyticsService.analyticsConstants.apiEvents.extras,
        data: checkOutDetailsResponse?.payload?.checkOutKey,
      },
      {
        platform: AnalyticsService.analyticsConstants.platform.gtag,
        type: AnalyticsService.analyticsConstants.eventType.pageView,
        data: AnalyticsService.analyticsConstants.apiEvents.extras,
      },
    ]);
  };

  const checkIfTransferIsSelected = () => {
    const isSelected =
      checkOutData?.additionalProductInfo?.transfersInfo?.transferOptions?.some(
        (option) => option.selected === true
      );
    setIsTransferSelected(isSelected);
  };

  const checkIfCarIsSelected = () => {
    const isSelected =
      checkOutData?.additionalProductInfo?.rentalCarInfo?.rentalCarOptions?.some(
        (option) => option.selected === true
      );
    setIsCarSelected(isSelected);
  };

  useEffect(() => {
    assignCheckOutData(checkOutData);
    checkIfTransferIsSelected();
    checkIfCarIsSelected();
    setShowBaggageComponent(
      checkOutData?.additionalProductInfo?.ancillaryServiceInfo
        ?.showBaggageComponent
    );
  }, [checkOutData]);

  useEffect(() => {
    if (!isWaitingForNetwork) {
      getCheckOutDetails();
    }
  }, [holidayKey]);

  const onBaggageSelectionChanged = async (baggageSelection) => {
    setIsWaitingForNetwork(true);
    setLoadingPassengerId(baggageSelection.travellerIdentifier);

    const ancillaryUpdateRequest = {
      Type: ancillaryTypes.Baggage,
      TravellerIdentifier: baggageSelection.travellerIdentifier,
      AncillaryOptionCode:
        baggageSelection.extrasOptionCode || baggageSelection.baggageOptionCode,
    };

    try {
      const newCheckoutData = await updateBaggageSelection(
        holidayKey,
        ancillaryUpdateRequest
      );

      dispatchData(updateCheckOutDataReducer(newCheckoutData));
      setCheckOutData(newCheckoutData);
    } catch (error) {
      console.error("Error updating baggage selection:", error);
    } finally {
      setIsWaitingForNetwork(false);
      setLoadingPassengerId(null);
    }
  };

  const onCabinBaggageSelectionChanged = async (cabinBaggageSelection) => {
    const { travellerIdentifier, ancillaryOptionCode } = cabinBaggageSelection;

    setLoadingPassengerId(travellerIdentifier);

    const ancillaryUpdateRequest = {
      Type: ancillaryTypes.CabinBaggage,
      TravellerIdentifier: travellerIdentifier,
      AncillaryOptionCode: ancillaryOptionCode,
    };

    try {
      const newCheckoutData = await updateBaggageSelection(
        holidayKey,
        ancillaryUpdateRequest
      );

      dispatchData(updateCheckOutDataReducer(newCheckoutData));
      setCheckOutData(newCheckoutData);
    } catch (error) {
      console.error("Error updating cabin baggage selection:", error);
    } finally {
      setIsWaitingForNetwork(false);
      setLoadingPassengerId(null);
    }
  };

  const handleViewCarConditions = async (optionKey) => {
    const carConditions = await axios.get(
      `${GetApiUrl()}/CheckOut/${
        checkOutData?.checkOutKey
      }/RentalCarConditions/${optionKey}`
    );
    return carConditions;
  };

  const onSelectTransfer = async (optionKey) => {
    if (!isCarSelected) {
      setIsWaitingForNetwork(true);
      const newCheckoutData = await axios.put(
        `${GetApiUrl()}/CheckOut/${holidayKey}/SelectTransfer`,
        {
          optionKey: optionKey,
        }
      );
      dispatchData(updateCheckOutDataReducer(newCheckoutData.data));
      setCheckOutData(newCheckoutData.data);
      setIsWaitingForNetwork(false);
    }
  };

  const navigateToHolidayPage = () => {
    window.location.href = checkOutData?.metaInfo?.navigateToHoliday;
  };

  const navigateToCheckoutPage = () => {
    window.location.href = checkOutData?.metaInfo?.navigateToCheckOut;
  };

  const handleBaggageValidation = (validationResult) => {
    if (validationResult) {
      setIsBaggageValid(true);
      if (isValidationInProgress) {
        navigateToCheckoutPage();
      }
    } else {
      setIsBaggageValid(false);
      if (isValidationInProgress) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setIsValidationInProgress(false);
  };

  return (
    <div className="extras-page-component">
      <PagesStepperBarComponent />
      <div className="extra-page-container">
        <div className="extra-page-left-column">
          <div className="extras-page-title">
            {contentfulExtrasPage && contentfulExtrasPage[0]?.fields?.pageTitle}
          </div>
          {showBaggageComponent && (
            <>
              <h1 className="section-heading-text">
                {contentfulExtrasPage &&
                  contentfulExtrasPage[0]?.fields?.cabinBaggageSectionTitle}
              </h1>
              <CabinLuggage
                contentfulLuggageSelection={contentfulLuggageSelection}
                setIsWaitingForNetwork={setIsWaitingForNetwork}
                contentfulCheckoutPassengerDetails={
                  contentfulCheckoutPassengerDetails
                }
                contentfulExtrasPage={contentfulExtrasPage}
                onBaggageSelectionChanged={onBaggageSelectionChanged}
                onCabinBaggageSelectionChanged={onCabinBaggageSelectionChanged}
                baggageDetails={
                  checkOutData?.additionalProductInfo?.ancillaryServiceInfo
                }
                isLoading={isWaitingForNetwork}
                loadingPassengerId={loadingPassengerId}
                isValidationInProgress={isValidationInProgress}
                onValidate={handleBaggageValidation}
                isBaggageValid={isBaggageValid}
              />
            </>
          )}
          <TransferComponent
            contentfulTransfer={
              contentfulTransfer && contentfulTransfer[0]?.fields
            }
            contentfulButtons={
              contentfulButtons && contentfulButtons[0]?.fields
            }
            transfersInfo={checkOutData?.additionalProductInfo?.transfersInfo}
            holidayKey={holidayKey}
            onSelectTransfer={onSelectTransfer}
            isCarSelected={isCarSelected}
            isWaitingForNetwork={isWaitingForNetwork}
          />
          <CarComponent
            rentalCarInfo={checkOutData?.additionalProductInfo?.rentalCarInfo}
            contentfulExtrasPage={contentfulExtrasPage}
            checkOutData={checkOutData}
            contentfulButtons={contentfulButtons}
            handleViewCarConditions={handleViewCarConditions}
            showCustomModal={showCustomModal}
            setCheckOutData={setCheckOutData}
            setIsCarSelected={setIsCarSelected}
            isTransferSelected={isTransferSelected}
          />
          <div className="action-container">
            <div className="back-checkout" onClick={navigateToHolidayPage}>
              {GetAffiliate() === affiliates.klm ? (
                <BackIconKLM />
              ) : GetAffiliate() === affiliates.transavia ? (
                <BackIconTransavia />
              ) : null}
              {contentfulButtons && contentfulButtons[0]?.fields?.back}
            </div>
            <div
              onClick={
                showBaggageComponent
                  ? () => setIsValidationInProgress(true)
                  : navigateToCheckoutPage
              }
              className="button-submit"
            >
              {contentfulButtons ? contentfulButtons[0]?.fields?.nextStep : ""}
            </div>
          </div>
        </div>
        <div className="shopping-cart-container">
          <ShoppingCartComponent checkOutData={checkOutData} />
        </div>
      </div>
    </div>
  );
};

import "./price-matrix-header.scss";

import klmChevronLeft from "../../assets/icons/klm/chevron-left-S.svg";
import klmChevronRight from "../../assets/icons/klm/chevron-right-S.svg";
import transaviaChevronLeft from "../../assets/icons/transavia/chevron-left-S.svg";
import transaviaChevronRight from "../../assets/icons/transavia/chevron-right-S.svg";
import chevronLeftDisabled from "../../assets/icons/common/chevron-left-disabled.svg";
import chevronRightDisabled from "../../assets/icons/common/chevron-right-disabled.svg";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import { useEffect, useState } from "react";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";
import { affiliateStyleRules } from "../../common/affiliate-style-rules";

export const PriceMatrixHeader = ({
  changeSelectedMonthForPriceMatrix,
  priceMatrixInfo,
  isLoading,
  setIsLoading,
}) => {
  const defaultNumberOfMonthsToShow = 8;
  const [numberOfMonthsForViewport, setNumberOfMonthsForViewport] = useState(
    defaultNumberOfMonthsToShow
  );
  const [enableLeftButton, setEnableLeftButton] = useState(false);
  const [enableRightButton, setEnableRightButton] = useState(true);
  const [monthsToShow, setMonthsToShow] = useState([]);
  const [monthDisplayOffset, setMonthDisplayOffset] = useState(0);
  const windowSizeDetector = useWindowSizeDetector();

  const monthsForExtraExtraLargeViewport = 8;
  const monthsForExtraLargeViewport = 6;
  const monthsForLarge1100Viewport = 6;
  const monthsForLargeViewport = 5;
  const monthsForMediumViewport = 4;
  const monthsForSmallViewport = 3;
  const monthsForMobileViewport = 2;

  useEffect(() => {
    switch (true) {
      case windowSizeDetector.width <= breakpoints.XS:
        setNumberOfMonthsForViewport(monthsForMobileViewport);
        break;
      case windowSizeDetector.width <= breakpoints.SM:
        setNumberOfMonthsForViewport(monthsForSmallViewport);
        break;
      case windowSizeDetector.width <= breakpoints.MD:
        setNumberOfMonthsForViewport(monthsForMediumViewport);
        break;
      case windowSizeDetector.width <= breakpoints.LG:
        setNumberOfMonthsForViewport(monthsForLargeViewport);
        break;
      case windowSizeDetector.width <= breakpoints.LG1100:
        setNumberOfMonthsForViewport(monthsForLarge1100Viewport);
        break;
      case windowSizeDetector.width <= breakpoints.XL:
        setNumberOfMonthsForViewport(monthsForExtraLargeViewport);
        break;
      default:
        setNumberOfMonthsForViewport(monthsForExtraExtraLargeViewport);
        break;
    }
  }, [windowSizeDetector]);

  useEffect(() => {
    if (priceMatrixInfo) {
      const monthData = priceMatrixInfo?.months;
      let newMonthsToShow = [];
      if (monthData) {
        let firstMonthIndex = 0;
        let startingIndex = firstMonthIndex + monthDisplayOffset;
        for (let i = startingIndex; i < monthData.length; i++) {
          if (newMonthsToShow.length < numberOfMonthsForViewport) {
            newMonthsToShow.push(monthData[i]);
          }
        }
        setMonthsToShow(newMonthsToShow);
      }
    }
  }, [priceMatrixInfo, numberOfMonthsForViewport, monthDisplayOffset]);

  useEffect(() => {
    if (isLoading) {
      setEnableLeftButton(false);
      setEnableRightButton(false);
    } else {
      setEnableLeftButton(true);
      setEnableRightButton(true);
    }
  }, [isLoading]);

  const HandleMonthClicked = async (selectedPeriod) => {
    if (!isLoading) {
      setIsLoading(true);
      await changeSelectedMonthForPriceMatrix(selectedPeriod);
    }
  };

  const HandleClickedNavigateLeft = () => {
    if (monthDisplayOffset - 1 >= 0) {
      if (monthDisplayOffset - 1 == 0) {
        setEnableLeftButton(false);
      }
      setMonthDisplayOffset(monthDisplayOffset - 1);
      setEnableRightButton(true);
    } else {
      setEnableLeftButton(false);
    }
  };

  const HandleClickedNavigateRight = () => {
    if (
      priceMatrixInfo.months.length -
        1 -
        numberOfMonthsForViewport -
        monthDisplayOffset >=
      0
    ) {
      if (
        priceMatrixInfo.months.length -
          1 -
          numberOfMonthsForViewport -
          monthDisplayOffset ===
        0
      ) {
        setEnableRightButton(false);
      }
      setMonthDisplayOffset(monthDisplayOffset + 1);
      setEnableLeftButton(true);
    } else {
      setEnableRightButton(false);
    }
  };

  const GetDisplayMonthName = (displayDate) => {
    return displayDate.split(" ")[0];
  };

  const GetDisplayYearName = (displayDate) => {
    return displayDate.split(" ")[1];
  };

  const GetAffiliateTextColour = () => {
    return GetAffiliate() == affiliates.klm ? "black" : "white";
  };

  return (
    <div className="price-matrix-header-container">
      <div
        className="price-matrix-header-month-navigation-button"
        style={{ cursor: enableLeftButton ? "pointer" : "default" }}
        onClick={() => HandleClickedNavigateLeft()}
      >
        <img
          src={
            enableLeftButton
              ? GetAffiliate() == affiliates.klm
                ? klmChevronLeft
                : transaviaChevronLeft
              : chevronLeftDisabled
          }
          alt="<"
        />
      </div>
      {monthsToShow.map((monthOption, index) => {
        return (
          <div
            key={index}
            className={`price-matrix-header-month-button ${
              monthOption.selected ? "month-button-selected" : ""
            } ${isLoading ? "month-button-disabled" : ""}`}
            onClick={() => HandleMonthClicked(monthOption)}
          >
            {GetDisplayMonthName(monthOption.displayDate)}
            <br />
            {GetDisplayYearName(monthOption.displayDate)}
          </div>
        );
      })}
      <div
        className="price-matrix-header-month-navigation-button"
        style={{ cursor: enableRightButton ? "pointer" : "default" }}
        onClick={() => HandleClickedNavigateRight()}
      >
        <img
          src={
            enableRightButton
              ? GetAffiliate() == affiliates.klm
                ? klmChevronRight
                : transaviaChevronRight
              : chevronRightDisabled
          }
          className={`${
            GetAffiliate() === affiliates.klm
              ? "klm-price-matrix-navigation-button"
              : "transavia-price-matrix-navigation-button"
          }`}
          alt=">"
        />
      </div>
    </div>
  );
};

import { ReactComponent as AutoIcon } from "../../assets/icons/klm/car-S.svg";

export const AutoComponent = ({
  contentfulHolidayPageSummary,
  data
}) => {
  
  return (
    <div className="car-component">
      <div className="section-header">
        <AutoIcon />
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.holidayPageSummaryExtension?.auto}
      </div>
      <div className="small-space">
        {data && contentfulHolidayPageSummary && (
          <span>
            {`${data?.description}`}
          </span>
        )}
      </div>
    </div>
  );
};

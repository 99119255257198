import { differenceInDays } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from "react";
import "./datepicker-dropdown-component.scss";

export const DatepickerDropdownComponent = ({
  priceCalendarData,
  getPriceCalendarData,
  contentfulSearchBar,
  startDate,
  endDate,
  setEndDate,
  priceCalendarDuration,
  setDurationPriceCalendar,
  hoverEndDate
}) => {
  const selectedOptionRef = useRef(null);
  const containerRef = useRef(null);
  const [isDropdownExtended, setDropdownExtended] = useState(false);
  const [durationOptions, setDurationOptions] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [inputHelper, setInputHelper] = useState();
  const travelDaysRange = useMemo(() => {
    return priceCalendarDuration
  }, [startDate, endDate]);
  const hasRunRef = useRef(false);

  useEffect(() => {
    if(startDate && endDate && !hasRunRef.current) {
      setInputValue(`${travelDaysRange} ${contentfulSearchBar && contentfulSearchBar.days}`);
      setInputHelper(travelDaysRange);
      getPriceCalendarData(travelDaysRange);
      hasRunRef.current = true;
    }
  }, [travelDaysRange])

  useEffect(() => {
    if (isDropdownExtended && selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [inputHelper, isDropdownExtended]);

  useEffect(() => {
    if (priceCalendarData?.durations?.length > 0) {
      setDurationOptions(priceCalendarData.durations);
    }
    const preselectedValueHelper = priceCalendarData?.durations?.find(
      (el) => el.selected
    );
    setInputValue(`${preselectedValueHelper?.durationInDays} ${contentfulSearchBar && contentfulSearchBar.days}`);
    setInputHelper(preselectedValueHelper?.durationInDays);
  }, [priceCalendarData]);

  const onDropdownOptionClick = (option) => {
    if (
      typeof option === "object" &&
      !(option instanceof String) &&
      option !== null
    ) {
      setInputValue(`${option?.durationInDays} ${contentfulSearchBar && contentfulSearchBar.days}`);
      setInputHelper(option?.durationInDays);
      setNewReturnDate(option.durationInDays);
      hasRunRef.current = false;
    } else {
      setInputValue(option);
      setInputHelper(option?.durationInDays);
      setNewReturnDate(option.durationInDays);
      hasRunRef.current = false;
    }
    setDurationPriceCalendar(option.durationInDays)
    getPriceCalendarData(option.durationInDays);

    setDropdownExtended(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setDropdownExtended(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef]);

  const setNewReturnDate = (daysToAdd) => {
    const originalDate = new Date(startDate);
    const newDate = new Date(originalDate);
    newDate.setDate(originalDate.getDate() + (daysToAdd - 1));
    setEndDate(newDate);
  };

  return (
    <div className="datepicker-dropdown-container">
      <div className="datepicker-input-dropdown-component" ref={containerRef}>
        <label
          className="label-description"
          onClick={() => setDropdownExtended(!isDropdownExtended)}
        >
          <div>{contentfulSearchBar && contentfulSearchBar.travelDuration}</div>
          {
            <div
              className={`datepicker-input-dropdown ${
                true ? "extras-input" : ""
              } ${isDropdownExtended ? "custom-focus" : ""}`}
            >
              <div className="input-placeholder">{inputValue}</div>
              <div className="icon-arrow">
                <div
                  className={
                    isDropdownExtended ? "icon-drop-up" : "icon-drop-down"
                  }
                ></div>
              </div>
              {isDropdownExtended && (
                <ul className="dropdown">
                  {durationOptions?.map((option, index) => (
                    <div className="dropdown-item-wrapper" key={index}>
                      <div className="dropdown-item-spacer"></div>
                      <li
                        className={`${
                          option?.durationInDays === inputHelper
                            ? "dropdown-item-selected"
                            : "dropdown-item"
                        }`}
                        ref={
                          option?.durationInDays === inputHelper
                            ? selectedOptionRef
                            : null
                        }
                        onClick={() => onDropdownOptionClick(option)}
                        key={option?.durationInDays}
                        value={option.durationInDays}
                      >
                        {option.durationInDays} {contentfulSearchBar && contentfulSearchBar.days}
                      </li>
                    </div>
                  ))}
                </ul>
              )}
            </div>
          }
        </label>
      </div>
    </div>
  );
};

import "./price-datepicker-children-component.scss";
import DatepickerHeaderComponent from "../../datepicker-header/datepicker-header-component";
import { Alert, CircularProgress } from "@mui/material";

const PriceDatepickerChildrenComponent = ({
  contentfulSearchBar,
  setIsOpen,
  contentfulButtons,
  handleClick,
  isDateRangeValid,
  showPriceCalendarFunction,
  isRegularCalendar,
  setIsRegularCalendar,
  holidayListKey,
  priceCalendarData,
  getPriceCalendarData,
  startDate,
  endDate,
  setEndDate,
  clearHover,
  isWaiting,
  defaultSearchUsed,
  priceCalendarDuration,
  setDurationPriceCalendar,
  hoverEndDate
}) => {

  return (
    <div onMouseEnter={clearHover}>
      {isWaiting && (
        <div className="calendar-spinner">
          <CircularProgress className="button-loading-spinner" />
        </div>
      )}

      <div className="children-content">
        <span className="circle circle-green"></span>
        {contentfulSearchBar && contentfulSearchBar?.bestPrice}
        <Alert className="price-calendar-disclaimer" severity="info">
          {contentfulSearchBar && contentfulSearchBar?.priceCalendarDisclaimer}
        </Alert>
      </div>
      <div className="datepicker-button-container price-datepicker-button-container">
        <button onClick={() => setIsOpen(false)} className="button">
          {contentfulButtons?.cancel}
        </button>
        <button
          onClick={handleClick}
          className={`${
            isDateRangeValid
              ? "button"
              : "save-button-disabled button-primary-medium"
          }`}
        >
          {contentfulButtons?.save}
        </button>
      </div>
      {showPriceCalendarFunction && (
        <DatepickerHeaderComponent
          isRegularCalendar={isRegularCalendar}
          setIsRegularCalendar={setIsRegularCalendar}
          contentfulSearchBar={contentfulSearchBar}
          holidayListKey={holidayListKey}
          priceCalendarData={priceCalendarData}
          getPriceCalendarData={getPriceCalendarData}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          defaultSearchUsed={defaultSearchUsed}
          priceCalendarDuration={priceCalendarDuration}
          setDurationPriceCalendar={setDurationPriceCalendar}
          hoverEndDate={hoverEndDate}
        />
      )}
    </div>
  );
};

export default PriceDatepickerChildrenComponent;

import { useContext, useEffect, useState } from "react";
import { contentType } from "../../common/constants/contentType";
import { footer } from "../../common/constants/footer";
import { getContentfulByContentType } from "../../services/contentful.service";
import ContentfulContext from "../../store/contentful/contentful-context";
import "./footer-component.scss";

export const FooterComponent = () => {
  const contentfulEntries = useContext(ContentfulContext);
  const [contentfulFooter, setStateContentfulFooter] = useState("");

  useEffect(() => {
    const getContentfulFooter = getContentfulByContentType(
      contentfulEntries,
      contentType.FOOTER
    );

    if (
      getContentfulFooter &&
      getContentfulFooter[0] &&
      getContentfulFooter[0].fields
    ) {
      processContentfulEntries(getContentfulFooter[0].fields);
    }
  }, [contentfulEntries]);

  const processContentfulEntries = (fields) => {
    const uniqueSellingPoints = processUniqueSellingPoints(
      fields[footer.uniqueSellingPoints]?.content
    );
    const contactUsLinks = processContactUsLinks(
      fields[footer.contactUsLinks]?.content
    );
    const contactUsLogoLink = processContactUsLogoLink(
      fields[footer.contactUsLogoLink]?.fields
    );
    const conditionsLinks = processConditionsLinks(
      fields[footer.conditionsLinks]?.content
    );
    const logoLinks = processLogoLinks(fields[footer.logoLinks]);
    const textContent = processTextContent(fields);

    setStateContentfulFooter({
      uniqueSellingPoints,
      contactUsLinks,
      contactUsLogoLink,
      conditionsLinks,
      logoLinks,
      textContent,
    });
  };

  const processUniqueSellingPoints = (content) => {
    const output = [];

    content?.forEach((item) => {
      if (item.nodeType.includes(footer.nodeTypes.asset)) {
        output.push({});
        output[output.length - 1].imageUrl = item.data.target.fields.file.url;
      } else if (
        item.nodeType.includes(footer.nodeTypes.heading) &&
        item.content[0].value.length > 0
      ) {
        output[output.length - 1].heading = item.content[0].value;
      } else if (
        item.nodeType.includes(footer.nodeTypes.paragraph) &&
        item.content[0].value.length > 0
      ) {
        output[output.length - 1].paragraph = item.content[0].value;
      }
    });

    return output;
  };

  const processContactUsLinks = (content) => {
    const output = content
      .map((e) => e.content)
      .flat()
      .filter((e) => e.data.uri)
      .map((e) => {
        return {
          uri: e.data.uri,
          value: e.content[0].value,
        };
      });

    return output;
  };

  const processContactUsLogoLink = (fields) => {
    const output = {
      title: fields.title,
      resourceUrl: fields.description,
      imageUrl: fields.file.url,
    };

    return output;
  };

  const processConditionsLinks = (content) => {
    const output = content
      .map((e) => e.content)
      .flat()
      .filter((e) => e.data.uri)
      .map((e) => {
        return {
          uri: e.data.uri,
          value: e.content[0].value,
        };
      });

    return output;
  };

  const processLogoLinks = (content) => {
    const output = content
      .map((e) => e.fields)
      .map((e) => {
        return {
          title: e.title,
          resourceUrl: e.description,
          imageUrl: e.file.url,
        };
      });

    return output;
  };

  const processTextContent = (fields) => {
    const output = fields[footer.textContent];
    return output;
  };

  return (
    <div className="footer-component">
      <div className="footer-component-content">
        <div className="unique-selling-points">
          {contentfulFooter?.uniqueSellingPoints?.map((usp, index) => {
            return (
              <div key={index} className="unique-selling-point">
                <div className="usp-icon-container">
                  <img src={usp.imageUrl} alt="" />
                </div>
                <div className="usp-text-container">
                  {usp.heading ? (
                    <div className="usp-text-heading">{usp.heading}</div>
                  ) : null}
                  <div className="usp-text-paragraph">{usp.paragraph}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="contact-and-conditions-links">
          <div className="contact-links">
            <div className="contact-links-header">
              {contentfulFooter?.textContent?.contactUs}
            </div>

            <div className="link-container">
              {contentfulFooter?.contactUsLinks?.map((link, index) => {
                return (
                  <div key={index} className="link">
                    <a
                      href={link.uri}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.value}
                    </a>
                  </div>
                );
              })}
            </div>

            <div className="logo-link-container">
              <a
                href={contentfulFooter?.contactUsLogoLink?.resourceUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={contentfulFooter?.contactUsLogoLink?.imageUrl}
                  alt=""
                />
              </a>
            </div>
          </div>

          <div className="conditions-links">
            <div className="conditions-links-header">
              {contentfulFooter?.textContent?.conditions}
            </div>

            <div className="link-container">
              {contentfulFooter?.conditionsLinks?.map((link, index) => {
                return (
                  <div key={index} className="link">
                    <a
                      href={link.uri}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.value}
                    </a>
                  </div>
                );
              })}
            </div>

            <div className="logo-link-container">
              <a
                href={contentfulFooter?.contactUsLogoLink?.resourceUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={contentfulFooter?.contactUsLogoLink?.imageUrl}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>

        <div className="logo-links">
          {contentfulFooter?.logoLinks?.map((logo, index) => {
            return (
              <div key={index} className="img-container">
                <a
                  href={logo.resourceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logo.imageUrl} alt="" />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

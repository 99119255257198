import axios from "axios";
import { GetApiUrl } from "./general.service";

const forcePageRefresh = () => {
  window.location.reload();
};

export const changeMonthForPriceMatrix = async (holidayKey, selectedPeriod) => {
  try {
    const requestUrl = `${GetApiUrl()}/Holiday/${holidayKey}/AlternativesMatrix?month=${
      selectedPeriod.month
    }&year=${selectedPeriod.year}`;
    const newMatrixInfo = await axios.get(requestUrl);
    return newMatrixInfo.data;
  } catch (error) {
    console.error("Error selecting a month:", error);
    forcePageRefresh();
  }
};

export const toggleAlternativesMatrix = async (holidayKey) => {
  try {
    const requestUrl = `${GetApiUrl()}/Holiday/${holidayKey}/ToggleAlternativesMatrix`;
    await axios.put(requestUrl);
  } catch (error) {
    console.error("Error toggling price matrix: ", error);
  }
};

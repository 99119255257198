import "./price-matrix-table.scss";

import klmChevronLeft from "../../assets/icons/klm/chevron-left-S.svg";
import klmChevronRight from "../../assets/icons/klm/chevron-right-S.svg";
import transaviaChevronLeft from "../../assets/icons/transavia/chevron-left-S.svg";
import transaviaChevronRight from "../../assets/icons/transavia/chevron-right-S.svg";
import chevronLeftDisabled from "../../assets/icons/common/chevron-left-disabled.svg";
import chevronRightDisabled from "../../assets/icons/common/chevron-right-disabled.svg";
import klmChevronDown from "../../assets/icons/klm/chevron-down.svg";
import klmChevronUp from "../../assets/icons/klm/chevron-up.svg";
import klmChevronDownDisabled from "../../assets/icons/klm/chevron-down-disabled.svg";
import klmChevronUpDisabled from "../../assets/icons/klm/chevron-up-disabled.svg";
import transaviaChevronDown from "../../assets/icons/transavia/Transavia_Chevron-down.svg";
import transaviaChevronUp from "../../assets/icons/transavia/Transavia_Chevron-up.svg";
import transaviaChevronDownDisabled from "../../assets/icons/transavia/chevron-down-disabled.svg";
import transaviaChevronUpDisabled from "../../assets/icons/transavia/chevron-up-disabled.svg";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";
import { useEffect, useRef, useState } from "react";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";
import { useSearchParams } from "react-router-dom";
import { searchParamsEnum } from "../../common/enums/searchParamsEnum";
import { LocalStorageService } from "../../services/local-storage.service";
import { LocalStorageEnum } from "../../common/enums/localStorageEnum";
import { PagesConstant } from "../../common/constants/pages";
import { CircularProgress } from "@mui/material";

export const PriceMatrixTable = ({
  priceMatrixInfo,
  contentfulHolidayPriceMatrix,
  isLoading,
}) => {
  const [contentfulData, setContentfulData] = useState();
  const [durationsToShow, setDurationsToShow] = useState();
  const [durationsBaseIndex, setDurationsBaseIndex] = useState();
  const [isMatrixInViewport, setIsMatrixInViewport] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isSearchPending, setIsSearchPending] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMobile, setIsMobile] = useState(false);
  const [durationArrowPrevDisabled, setDurationArrowPrevDisabled] =
    useState(false);
  const [durationArrowNextDisabled, setDurationArrowNextDisabled] =
    useState(false);
  const [navigationArrowPrevDisabled, setNavigationArrowPrevDisabled] =
    useState(false);
  const [navigationArrowNextDisabled, setNavigationArrowNextDisabled] =
    useState(false);
  const [tableScrollPositionStep, setTableScrollPositionStep] = useState(0);
  const [scrollStepSize, setScrollStepSize] = useState();
  const [numberOfScrollSteps, setNumberOfScrollSteps] = useState();
  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  const windowSizeDetector = useWindowSizeDetector();

  const prevBaseIndex = useRef();
  const selectedCell = useRef();
  const matrixContainer = useRef();
  const matrixTable = useRef();
  const datesRow = useRef();

  const handleCellClick = (cellKey) => {
    if (cellKey) {
      localStorage.setItem(LocalStorageEnum.CELL_SELECTED, JSON.stringify(cellKey));
    }
  };

  useEffect(() => {
    if (isLoading) {
      setNavigationArrowPrevDisabled(true);
      setNavigationArrowNextDisabled(true);
    } else {
      setNavigationArrowPrevDisabled(false);
      setNavigationArrowNextDisabled(false);
    }
  }, [isLoading]);

  useEffect(() => {
    windowSizeDetector.width < breakpoints.SM
      ? setIsMobile(true)
      : setIsMobile(false);
  }, [windowSizeDetector]);

  useEffect(() => {
    if (contentfulHolidayPriceMatrix) {
      setContentfulData(contentfulHolidayPriceMatrix[0]?.fields);
    }
  }, [contentfulHolidayPriceMatrix]);

  useEffect(() => {
    if (priceMatrixInfo && Object.keys(priceMatrixInfo).length > 0) {
      prevBaseIndex.current = durationsBaseIndex;
      setDurationsBaseIndex(
        priceMatrixInfo.durations.findIndex((item) => item.selected === true)
      );
      getDateDurationsToShow(priceMatrixInfo.durations);
    }
  }, [priceMatrixInfo]);

  useEffect(() => {
    setDurationArrowNextDisabled(false);
    setDurationArrowPrevDisabled(false);
    if (durationsBaseIndex !== prevBaseIndex.current) {
      getDateDurationsToShow(priceMatrixInfo.durations);
    }
    if (durationsBaseIndex >= priceMatrixInfo.durations.length - 3) {
      setDurationArrowNextDisabled(true);
    } else if (durationsBaseIndex <= 2) {
      setDurationArrowPrevDisabled(true);
    }
  }, [durationsBaseIndex]);

  useEffect(() => {
    if (!scrollStepSize) {
      getScrollStepSize();
    }
    if (matrixTable.current) {
      const container = matrixTable.current;
      const handleScroll = () => {
        const currentScrollLeft = container.scrollLeft;
        let nextStep = tableScrollPositionStep;
        if (currentScrollLeft > lastScrollLeft) {
          setTableScrollPositionStep(nextStep + 1);
        } else if (currentScrollLeft < lastScrollLeft) {
          setTableScrollPositionStep(nextStep - 1);
        }
        setLastScrollLeft(currentScrollLeft);
        setTableScrollPositionStep(nextStep);
      };
      container.addEventListener("scroll", handleScroll);
    }
  }, [matrixTable.current]);

  useEffect(() => {
    if (selectedCell.current && isMatrixInViewport && !hasScrolled) {
      selectedCell.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      setHasScrolled(true);
    }
  }, [isMatrixInViewport]);

  useEffect(() => {
    if (hasScrolled && scrollStepSize && matrixTable?.current?.scrollLeft) {
      setLastScrollLeft(matrixTable.current.scrollLeft);
      const currentStep = Math.ceil(
        matrixTable.current.scrollLeft / scrollStepSize
      );
      setTableScrollPositionStep(currentStep + 1);
    }
  }, [hasScrolled, scrollStepSize, matrixTable?.current?.scrollLeft]);

  useEffect(() => {
    if (tableScrollPositionStep === numberOfScrollSteps) {
      setNavigationArrowNextDisabled(true);
      setNavigationArrowPrevDisabled(false);
    } else if (tableScrollPositionStep === 1) {
      setNavigationArrowPrevDisabled(true);
      setNavigationArrowNextDisabled(false);
    } else {
      setNavigationArrowNextDisabled(false);
      setNavigationArrowPrevDisabled(false);
    }
  }, [tableScrollPositionStep]);

  useEffect(() => {
    if (isSearchPending) {
      setIsSearchPending(false);
      window.location.href = `${
        PagesConstant.HOLIDAY
      }?${searchParams.toString()}`;
    }
  }, [isSearchPending]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsMatrixInViewport(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: isMobile ? "0px" : "-500px",
        threshold: 0,
      }
    );
    if (matrixContainer.current) {
      observer.observe(matrixContainer.current);
    }
    return () => {
      if (matrixContainer.current) {
        observer.unobserve(matrixContainer.current);
      }
    };
  }, [matrixContainer.current]);

  const getDateDurationsToShow = (fullListOfRanges) => {
    if (durationsBaseIndex === -1) {
      return [];
    }
    const totalItems = 5;
    let startIndex = Math.max(0, durationsBaseIndex - 2);
    let endIndex = startIndex + totalItems - 1;
    if (endIndex >= fullListOfRanges?.length) {
      endIndex = fullListOfRanges?.length - 1;
      startIndex = Math.max(0, endIndex - (totalItems - 1));
    }
    if (startIndex < 0) {
      startIndex = 0;
      endIndex = Math.min(fullListOfRanges.length - 1, totalItems - 1);
    }
    setDurationsToShow(fullListOfRanges?.slice(startIndex, endIndex + 1));
  };

  const updateDurationsToShow = (getNext) => {
    if (getNext) {
      if (durationsBaseIndex !== priceMatrixInfo.durations.length - 3)
        setDurationsBaseIndex(durationsBaseIndex + 1);
    } else {
      if (durationsBaseIndex > 2) setDurationsBaseIndex(durationsBaseIndex - 1);
    }
  };

  const getScrollStepSize = () => {
    if (matrixTable.current) {
      const container = matrixTable.current;
      const datesRowWidth = datesRow.current.getBoundingClientRect().width;
      const columnsPerViewport = Math.round(
        container.getBoundingClientRect().width / 92
      );
      const stepSize = columnsPerViewport * 92;
      setScrollStepSize(stepSize);
      setNumberOfScrollSteps(Math.ceil(datesRowWidth / stepSize));
    }
  };

  const updateDatesToShow = (getNext) => {
    const container = matrixTable.current;
    container.scrollBy({
      left: getNext ? scrollStepSize : -scrollStepSize,
      behavior: "smooth",
    });

    const handleScroll = () => {
      const nextStep = getNext
        ? tableScrollPositionStep + 1
        : tableScrollPositionStep - 1;
      setTableScrollPositionStep(nextStep);
    };

    container.addEventListener("scroll", handleScroll);
  };

  const getCellClasses = (cellData) => {
    let classes = ["price-matrix-table__cell"];
    if (cellData.selected) {
      classes.push("cell-selected");
    }
    if (cellData.offerPrice) {
      classes.push("cell-offer");
    }
    if (cellData.noPriceFound) {
      classes.push("cell-disabled");
    }

    return classes.join(" ");
  };

  const generateDurationCells = () => {
    const getCellClasses = (duration) => {
      let classes = ["price-matrix-table__duration-cell"];
      if (duration.selected) {
        classes.push("duration-cell-selected");
      }

      return classes.join(" ");
    };

    if (priceMatrixInfo && durationsToShow) {
      return durationsToShow.map((duration, durationIndex) => (
        <div
          className={getCellClasses(duration)}
          key={`duration-${durationIndex}`}
        >
          {duration.durationInDays} {contentfulData?.days}
        </div>
      ));
    }
  };

  const generateDateCells = () => {
    const getCellClasses = (date) => {
      let classes = ["price-matrix-table__date-cell"];
      if (date.selected) {
        classes.push("date-cell-selected");
      }
      if (date.noPriceFound) {
        classes.push("cell-disabled");
      }

      return classes.join(" ");
    };

    if (priceMatrixInfo && durationsToShow) {
      return priceMatrixInfo.dates.map((date, dateIndex) => {
        return (
          <div className={getCellClasses(date)} key={`date-${dateIndex}`}>
            <span>{date?.day}</span>
            <br />
            <span>{date?.displayDate}</span>
          </div>
        );
      });
    }
  };

  const newHolidaySearch = (cell) => {
    const updatedParams = new URLSearchParams(searchParams);
    const newDepartureDate = cell.searchDepartureDate;
    const newReturnDate = cell.searchReturnDate;
    const presetDepartureDateFlexibility =
      LocalStorageService.getLocalStorageItem(
        LocalStorageEnum.SELECTED_DATES
      )?.departureDateFlexibility;
    updatedParams.set(searchParamsEnum.departureDate, newDepartureDate);
    updatedParams.set(searchParamsEnum.returnDate, newReturnDate);
    LocalStorageService.setLocalStorageItem(LocalStorageEnum.SELECTED_DATES, {
      departureDate: newDepartureDate,
      returnDate: newReturnDate,
      departureDateFlexibility: presetDepartureDateFlexibility,
    });
    setSearchParams(updatedParams);
    setIsSearchPending(true);
  };

  const buildDurationsNextArrowClasses = () => {
    const classes = ["duration-column__arrow-next"];
    if (durationArrowNextDisabled) {
      classes.push("arrow-disabled");
    }
    return classes.join(" ");
  };

  const buildDurationsPrevArrowClasses = () => {
    const classes = ["duration-column__arrow-prev"];
    if (durationArrowPrevDisabled) {
      classes.push("arrow-disabled");
    }
    return classes.join(" ");
  };

  const buildNavigationPrevArrowClasses = () => {
    const classes = ["date-navigation__arrow-prev"];
    if (navigationArrowPrevDisabled) {
      classes.push("arrow-disabled");
    }
    return classes.join(" ");
  };

  const buildNavigationNextArrowClasses = () => {
    const classes = ["date-navigation__arrow-next"];
    if (navigationArrowNextDisabled) {
      classes.push("arrow-disabled");
    }
    return classes.join(" ");
  };

  const colorSpecification = (
    <div className="price-matrix-color-specification">
      <div className="color-specification__color-sample"></div>
      <span className="color-specification__color-meaning">
        {contentfulData?.lowestPrice}
      </span>
    </div>
  );

  return (
    contentfulData &&
    priceMatrixInfo && (
      <div className="price-matrix-wrapper">
        <div className="price-matrix-sub-header">
          <p className="price-matrix-date-range-heading">
            {`${priceMatrixInfo.selectedHolidayDate} (${priceMatrixInfo.selectedHolidayNumberOfDays} ${contentfulData.days}, ${priceMatrixInfo.selectedHolidayNumberOfNights} ${contentfulData.nights})`}
          </p>
          {!isMobile && (
            <div
              className={`price-matrix-date-navigation ${
                isLoading ? "date-navigation-disabled" : ""
              }`}
            >
              <div
                className={buildNavigationPrevArrowClasses()}
                onClick={() => updateDatesToShow(false)}
              >
                <img
                  src={
                    !navigationArrowPrevDisabled
                      ? GetAffiliate() == affiliates.klm
                        ? klmChevronLeft
                        : transaviaChevronLeft
                      : chevronLeftDisabled
                  }
                  alt="Previous dates"
                />
              </div>
              <span>{contentfulData.departureDate}</span>
              <div
                className={buildNavigationNextArrowClasses()}
                onClick={() => updateDatesToShow(true)}
              >
                <img
                  src={
                    !navigationArrowNextDisabled
                      ? GetAffiliate() == affiliates.klm
                        ? klmChevronRight
                        : transaviaChevronRight
                      : chevronRightDisabled
                  }
                  alt="Next dates"
                />
              </div>
            </div>
          )}
        </div>
        {isMobile && colorSpecification}
        <div className="price-matrix-table-container" ref={matrixContainer}>
          <div className="price-matrix-table__duration-column">
            <div
              className={buildDurationsPrevArrowClasses()}
              onClick={() => updateDurationsToShow(false)}
            >
              <img
                src={
                  GetAffiliate() == affiliates.klm
                    ? durationArrowPrevDisabled
                      ? klmChevronUpDisabled
                      : klmChevronUp
                    : durationArrowPrevDisabled
                    ? transaviaChevronUpDisabled
                    : transaviaChevronUp
                }
                alt="Previous durations"
              />
            </div>
            <div
              className="price-matrix-table__duration-cell duration-cell-empty"
              key="duration-empty"
            ></div>
            {generateDurationCells()}
            <div
              className={buildDurationsNextArrowClasses()}
              onClick={() => updateDurationsToShow(true)}
            >
              <img
                src={
                  GetAffiliate() == affiliates.klm
                    ? durationArrowNextDisabled
                      ? klmChevronDownDisabled
                      : klmChevronDown
                    : durationArrowNextDisabled
                    ? transaviaChevronDownDisabled
                    : transaviaChevronDown
                }
                alt="Next durations"
              />
            </div>
          </div>
          <div
            className={`price-matrix-table  ${
              isLoading ? "price-matrix-table-loading" : ""
            }`}
            ref={matrixTable}
          >
            <div className="price-matrix-table__dates-row" ref={datesRow}>
              {generateDateCells()}
            </div>
            {durationsToShow &&
              durationsToShow.map((duration, durationIndex) => (
                <div
                  className="price-matrix-table__row"
                  key={`row-${durationIndex}`}
                >
                  {duration.priceMatrixCellRecords.map((cell, cellIndex) => (
                    <div
                    className={getCellClasses(cell)}
                    key={`${durationIndex}-${cellIndex}`}
                    ref={cell.selected ? selectedCell : null}
                    onClick={() => {
                      if (!cell.noPriceFound) {
                        newHolidaySearch(cell);
                        handleCellClick(`${durationIndex}-${cellIndex}`); 
                      }
                    }}
                  >
                    {!cell.noPriceFound ? `€${cell.price}` : cell.price}
                  </div>
                  
                  ))}
                </div>
              ))}
            {isLoading && (
              <div className="price-matrix-table__loading-spinner">
                <CircularProgress className="spinner-icon" />
              </div>
            )}
          </div>
        </div>
        <div className="price-matrix-footer">
          <p className="footer__note">{contentfulData.matrixFooterNote}</p>
          {!isMobile && colorSpecification}
        </div>
      </div>
    )
  );
};

import { LocalStorageService } from "../../services/local-storage.service";

export const mealTypes = [
  {
    culture: "en-GB",
    codes: {
      AI: "AI",
      AIL: "AIL",
      AIP: "AIP",
      AIU: "AIU",
      BB: "BB",
      DO: "DO",
      FB: "FB",
      FBP: "FBP",
      HB: "HB",
      HBP: "HBP",
      LO: "LO",
      RO: "RO",
      SC: "SC",
    },
    types: [
      { AI: "All Inclusive" },
      { AIL: "All-Inclusive Light" },
      { AIP: "All-Inclusive Plus" },
      { AIU: "All-Inclusive Ultra" },
      { BB: "Bed & Breakfast" },
      { DO: "Dinner Only" },
      { FB: "Full Board" },
      { FBP: "Full-Board Plus" },
      { HB: "Half Board" },
      { HBP: "Half-Board Plus" },
      { LO: "Lunch Only" },
      { RO: "Room Only (No Meal)}" },
      { SC: "Breakfast excluded" },
    ],
  },
  {
    culture: "nl-NL",
    types: [
      { AI: "All inclusive" },
      { AIL: "All-inclusive light" },
      { AIP: "All-inclusive plus" },
      { AIU: "All-inclusive ultra" },
      { BB: "Bed & Breakfast" },
      { DO: "Alleen diner" },
      { FB: "Volpension" },
      { FBP: "Volpension plus" },
      { HB: "Halfpension" },
      { HBP: "Halfpension plus" },
      { LO: "Alleen lunch" },
      { RO: "Alleen verblijf (geen maaltijd)" },
      { SC: "Exclusief ontbijt" },
    ],
  },
];

export const GetMealType = (mealTypeCode) => {
  if (mealTypeCode) {
    const mealTypesForCulture = mealTypes.filter(
      (x) => x.culture === LocalStorageService.getCulture()
    );
    const relevantMealType = mealTypesForCulture[0].types.find(
      (x) => Object.keys(x)[0] === mealTypeCode
    );
    return Object.values(relevantMealType)[0];
  }
  return "Unknown meal type";
};

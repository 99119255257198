import { CircularProgress } from "@mui/material";
import { ReactComponent as SelectedIconKLM } from "../../assets/icons/check-round-S.klm.svg";
import "./accommodation-select-button.component.scss";
import { useEffect, useState } from "react";

export const AccommodationSelectButton = ({
  isSelected,
  contentfulButtons,
  onClick,
  isLoading,
  roomInfo,
  ...props
}) => {
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  useEffect(() => {
    setShowLoadingSpinner(false);
  }, [roomInfo]);

  const handleClick = () => {
    if (onClick) {
      onClick(roomInfo);
      setShowLoadingSpinner(true);
    }
  };

  return isSelected ? (
    <div className="status__selected">
      <SelectedIconKLM className="selected__icon" />
      <span className="selected__text">
        {contentfulButtons && contentfulButtons[0]?.fields.selected}
      </span>
    </div>
  ) : (
    <button
      className="status__select"
      onClick={handleClick}
      disabled={isLoading}
      {...props}
    >
      {isLoading && showLoadingSpinner && (
        <CircularProgress className="button__loading-spinner" size={4} />
      )}
      {contentfulButtons && isLoading && showLoadingSpinner
        ? contentfulButtons[0]?.fields?.loadingState
        : contentfulButtons[0]?.fields?.select}
    </button>
  );
};

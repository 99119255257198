export const contentfulEntriesReducer = {
  BUTTONS: "contentfulButtons",
  ROOM_ALLOCATION: "roomAllocation",
  HOLIDAY_PAGE: "contentfulHolidayPage",
  HOLIDAY_STEPPER: "contentfulHolidayStepper",
  HOLIDAY_PAGE_SUMMARY: "HOLIDAY_PAGE_SUMMARY",
  HOLIDAY_PAGE_PRICE_MATRIX: "holidayPagePriceMatrix",
  CUSTOM_MODALS: "CustomModals",
  HEADER: "contentfulHeader",
  CHECKOUT_PASSENGER_DETAILS: "checkoutPassengerDetails",
  CHECKOUT_FORM_VALIDATORS: "checkoutFormValidators",
  PAYMENT_PAGE: "paymentPage",
  ADYEN_COMPONENT: "adyenPayment",
  FOOTER: "FOOTER",
  EXTRAS_PAGE: "extrasPage",
  CONFIRMATION_PAGE: "confirmationPage",
  EXCEPTIONS: "exceptions",
  LUGGAGE_SELECTION: "luggageSelection",
  WAITING_MODAL: "waitingModal",
  WAITING_MODAL_MESSAGE: "waitingModalMessage",
  FILTERS: "contentfulFilters"
};

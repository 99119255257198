import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insurance } from "../../common/constants/insurance";
import { GetAffiliate } from "../../services/general.service.js";
import { LocalStorageService } from "../../services/local-storage.service.js";
import {
  approveInsuranceConditions,
  getCheckOutDetailsCart,
  selectInsurance,
  updateCheckOutDataReducer,
} from "../../store/shopping/slice";
import { GenericButton } from "../generic-button/generic-button";
import { insuranceSpinnerMessage } from "./insurance-spinner.js";
import "./insurance.component.scss";

export const InsuranceComponent = ({
  contentfulPaymentPage,
  insuranceInfoDetails,
}) => {
  const [textToDisplay, setStateTextToDisplay] = useState(null);
  const [
    contentfulCancellationInsurance,
    setStateContentfulCancellationInsurance,
  ] = useState(null);
  const [contentfulTravelInsurance, setStateContentfulTravelInsurance] =
    useState(null);
  const [
    contentfulCombinationInsurance,
    setStateContentfulCombinationInsurance,
  ] = useState(null);
  const [insuranceInfo, setInsuranceInfo] = useState(null);
  const [apiInsuranceInfo, setStateApiInsuranceInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onFirstLoad, setOnFirstLoad] = useState(false);
  const [isLoadingInsurance, setIsLoadingInsurance] = useState(false);
  const [selectedInsuranceName, setSelectedInsuranceName] = useState(null);
  const holidayKey = useSelector((state) => state.holidayData.holidayKey);
  const checkOutData = useSelector((state) => state.checkOut.checkOutData);
  const dispatch = useDispatch();
  const defaultPayload = useRef({});
  const [spinnerDescription, setSpinnerDescription] = useState("");
  const [isAgreementClicked, setIsAgreementClicked] = useState(false);
  const [isLoadingAgreement, setIsLoadingAgreement] = useState(false);

  useEffect(() => {
    presetDefaultPayload();
    checkInsuranceSelectionOnReload();
  }, []);

  useEffect(() => {
    if (!insuranceInfo) {
      setInsuranceInfo(checkOutData?.additionalProductInfo?.insuranceInfo);
      setOnFirstLoad(true);
    } else {
      setOnFirstLoad(false);
    }
  }, [checkOutData, insuranceInfo]);

  useEffect(() => {
    getInsuranceSpinnerDescription();
  }, [LocalStorageService.getCulture()]);

  const getInsuranceSpinnerDescription = () => {
    const messageByAffiliate = insuranceSpinnerMessage.find(
      (message) => message.affiliate === GetAffiliate()
    );
    const culture = LocalStorageService.getCulture();
    const messageByCulture = messageByAffiliate.content.find(
      (message) => message.cultureCode === culture
    );
    setSpinnerDescription(messageByCulture.spinnerDescription);
  };

  useEffect(() => {
    getInsuranceSpinnerDescription();
    if (contentfulPaymentPage) {
      setStateTextToDisplay(contentfulPaymentPage[0]?.fields?.insurance);
      setStateContentfulCancellationInsurance(
        contentfulPaymentPage[0]?.fields?.cancellationInsurance
      );
      setStateContentfulTravelInsurance(
        contentfulPaymentPage[0]?.fields?.travelInsurance
      );
      setStateContentfulCombinationInsurance(
        contentfulPaymentPage[0]?.fields?.combinationInsurance
      );
    }
  }, [contentfulPaymentPage]);

  useEffect(() => {
    if (checkOutData) {
      setStateApiInsuranceInfo(
        checkOutData?.additionalProductInfo?.insuranceInfo
      );
      if (contentfulPaymentPage) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
  }, [checkOutData, contentfulPaymentPage]);

  const handleAgreementClick = async () => {
    setIsAgreementClicked(true);
    setIsLoadingAgreement(true);
    await performInsuranceAction(insurance.action.approveInsuranceTerms, {});
    setIsLoadingAgreement(false);
    setIsAgreementClicked(false);
  };

  const checkInsuranceSelectionOnReload = () => {
    const insuranceInfoData = checkOutData?.additionalProductInfo?.insuranceInfo;
    const cancellationInsuranceSelected = insuranceInfoData?.cancellationInsuranceSelected;
    const combinationInsuranceSelected = insuranceInfoData?.combinationInsuranceSelected;
    const travelInsuranceSelected = insuranceInfoData?.travelInsuranceSelected;
    const defaultInsuranceSelection = cancellationInsuranceSelected || combinationInsuranceSelected || travelInsuranceSelected;
    setSelectedInsuranceName(defaultInsuranceSelection ? insurance.type.selectedInsurance: insurance.type.selectNoInsurance);
  }

  const insuranceOptionDetails = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return node.nodeType === "paragraph" &&
          children.length === 1 &&
          children[0] !== "" ? (
          <>
            <div className="entry">
              <div className="icon-container">
                <div className="icon"></div>
              </div>
              <div className="text-container">{children}</div>
            </div>
          </>
        ) : null;
      },

      [BLOCKS.HEADING_6]: (node, children) => {
        return node.nodeType !== "paragraph" ? (
          <>
            <div className="insurance-option-disclaimer">{children}</div>
          </>
        ) : null;
      },

      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  };

  const presetDefaultPayload = (data) => {
    if (data) {
      Object.keys(insurance.type).forEach((type) => {
        if (type !== data) {
          defaultPayload.current[type] = false;
        }
      });
    } else {
      Object.keys(insurance.type).forEach(
        (type) => (defaultPayload.current[type] = false)
      );
    }
  };

  const performInsuranceAction = async (action, data) => {
    setIsLoadingInsurance(true);
    if (action === insurance.action.selectInsuranceOption) {
      if (data === insurance.type.selectNoInsurance) {
        presetDefaultPayload(data);
        defaultPayload.current[data] = !defaultPayload.current[data];
      } else {
        presetDefaultPayload();
        defaultPayload.current[data] = true;
      }
    }
    const response = await dispatch(
      action === insurance.action.selectInsuranceOption
        ? selectInsurance({
            holidayKey: holidayKey,
            data: defaultPayload.current,
          })
        : approveInsuranceConditions({ holidayKey: holidayKey, data: data })
    );

    if (response.meta.requestStatus === "fulfilled") {
      dispatch(updateCheckOutDataReducer(response.payload));
      dispatch(getCheckOutDetailsCart(holidayKey));
      if (response.payload?.status === 200) {
        setIsLoadingInsurance(false);
      }
    } else if (response.meta.requestStatus === "rejected") {
      setIsLoading(false);
    }
  };

  const onInsuranceSelectSetName = (insuranceName) => {
    setSelectedInsuranceName(insuranceName);
  };

  const header = (
    <div className="header">
      {textToDisplay ? textToDisplay.headerText : "Insurance"}
    </div>
  );

  const insuranceIntro = (
    <div className="insurance-intro">
      {textToDisplay && textToDisplay.introText
}
    </div>
  );

  const handleCancelInsurance = () => {
    performInsuranceAction(
      insurance.action.selectInsuranceOption,
      insurance.type.selectRemoveInsurance
    );
    onInsuranceSelectSetName(insurance.type.selectRemoveInsurance);
  };

  const cancellationInsurance = (data) => {
    return (
      <div
        className={`
          ${
            data?.cancellationInsuranceSelected
              ? "insurance-option option-selected"
              : data?.noInsuranceSelected && !isLoadingInsurance
              ? "insurance-option option-disabled"
              : "insurance-option"
          }
            ${
              isLoadingInsurance &&
              selectedInsuranceName !==
                insurance.type.selectCancellationInsurance
                ? "insurance-option option-disabled"
                : "insurance-option"
            }`}
      >
        <div className="insurance-option-header">
          { textToDisplay && textToDisplay?.insuranceOptions?.headers?.cancellationInsurance }
        </div>
        <div className="insurance-option-details">
          {contentfulCancellationInsurance &&
            documentToReactComponents(
              contentfulCancellationInsurance,
              insuranceOptionDetails
            )}
        </div>
        {apiInsuranceInfo?.cancellationInsuranceSelected ? (
          !isLoadingInsurance ? (
            <div className="insurance-option-selected">
              <div>
                <div className="icon-container">
                  <div className="icon"></div>
                </div>
                <div className="text">
                  {textToDisplay
                    ? textToDisplay?.insuranceOptions?.common?.info?.addedText
                    : "Added"}
                </div>
              </div>
              <div
                className="deselect-insurance-option"
                onClick={handleCancelInsurance}
              >
                {textToDisplay
                  ? textToDisplay?.insuranceOptions?.common?.buttons?.removeText
                  : "Remove"}
              </div>
            </div>
          ) : (
            <div className="spinner-button-wrapper">
              <div className="spinner-button">
                <CircularProgress className="button-loading-spinner" size={6} />
                <div className="loading-text loading-text-removal">
                  {isAgreementClicked
                    ? textToDisplay.insuranceOptions?.common?.buttons
                        ?.loadingText
                    : textToDisplay.insuranceOptions?.common?.buttons
                        ?.toRemoveText}
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="insurance-option-selectable">
            <div className="insurance-option-price">
              €{" "}
              {data?.cancellationInsurancePrice ??
                insuranceInfo?.cancellationInsurancePrice}
            </div>
            <div className="insurance-option-selector">
              <GenericButton
                className="button-select-insurance-option"
                disabledState={disableButton(
                  insurance.type.selectCancellationInsurance
                )}
                onClick={() => {
                  performInsuranceAction(
                    insurance.action.selectInsuranceOption,
                    insurance.type.selectCancellationInsurance
                  );
                  onInsuranceSelectSetName(
                    insurance.type.selectCancellationInsurance
                  );
                }}
              >
                {(!isLoadingInsurance ||
                  (isLoadingInsurance &&
                    selectedInsuranceName !==
                      insurance.type.selectCancellationInsurance)) &&
                  textToDisplay?.insuranceOptions?.common?.buttons?.selectText}
                {isLoadingInsurance &&
                  selectedInsuranceName ===
                    insurance.type.selectCancellationInsurance && (
                    <div className="spinner-button-wrapper">
                      <div className="spinner-button">
                        <CircularProgress
                          className="button-loading-spinner"
                          size={8}
                        />
                        <div className="loading-text">
                          {
                            textToDisplay?.insuranceOptions?.common?.buttons
                              ?.loadingText
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </GenericButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  const travelInsurance = (data) => {
    return (
      <div
        className={`${
          data?.travelInsuranceSelected
            ? "insurance-option option-selected"
            : data?.noInsuranceSelected && !isLoadingInsurance
            ? "insurance-option option-disabled"
            : "insurance-option"
        }
          ${
            isLoadingInsurance &&
            selectedInsuranceName !== insurance.type.selectTravelInsurance
              ? "insurance-option option-disabled"
              : "insurance-option"
          }`}
      >
        <div className="insurance-option-header">
          {textToDisplay
            ? textToDisplay?.insuranceOptions?.headers?.travelInsurance
            : "Travel insurance"}
        </div>
        <div className="insurance-option-details">
          {contentfulTravelInsurance &&
            documentToReactComponents(
              contentfulTravelInsurance,
              insuranceOptionDetails
            )}
        </div>
        {apiInsuranceInfo?.travelInsuranceSelected ? (
          !isLoadingInsurance ? (
            <div className="insurance-option-selected">
              <div>
                <div className="icon-container">
                  <div className="icon"></div>
                </div>
                <div className="text">
                  {textToDisplay
                    ? textToDisplay?.insuranceOptions?.common?.info?.addedText
                    : "Added"}
                </div>
              </div>
              <div
                className="deselect-insurance-option"
                onClick={handleCancelInsurance}
              >
                { textToDisplay && textToDisplay.insuranceOptions?.common?.buttons?.removeText }
              </div>
            </div>
          ) : (
            <div className="spinner-button-wrapper">
              <div className="spinner-button">
                <CircularProgress className="button-loading-spinner" size={6} />
                <div className="loading-text loading-text-removal">
                  {isAgreementClicked
                    ? textToDisplay.insuranceOptions?.common?.buttons
                        ?.loadingText
                    : textToDisplay.insuranceOptions?.common?.buttons
                        ?.toRemoveText}
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="insurance-option-selectable">
            <div className="insurance-option-price">
              €{" "}
              {data?.travelInsurancePrice ??
                insuranceInfo?.travelInsurancePrice}
            </div>
            <div className="insurance-option-selector">
              <GenericButton
                className="button-select-insurance-option"
                disabledState={disableButton(
                  insurance.type.selectTravelInsurance
                )}
                onClick={() => {
                  performInsuranceAction(
                    insurance.action.selectInsuranceOption,
                    insurance.type.selectTravelInsurance
                  );
                  onInsuranceSelectSetName(
                    insurance.type.selectTravelInsurance
                  );
                }}
              >
                {(!isLoadingInsurance ||
                  (isLoadingInsurance &&
                    selectedInsuranceName !==
                      insurance.type.selectTravelInsurance)) &&
                  textToDisplay?.insuranceOptions?.common?.buttons?.selectText}
                {isLoadingInsurance &&
                  selectedInsuranceName ===
                    insurance.type.selectTravelInsurance && (
                    <div className="spinner-button-wrapper">
                      <div className="spinner-button">
                        <CircularProgress
                          className="button-loading-spinner"
                          size={8}
                        />
                        <div className="loading-text">
                          {
                            textToDisplay.insuranceOptions?.common?.buttons
                              ?.loadingText
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </GenericButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  const disableButton = (insuranceType) => {
    return (
      isLoadingInsurance ||
      selectedInsuranceName === insuranceType ||
      (!apiInsuranceInfo?.combinationInsuranceSelected &&
        selectedInsuranceName === insuranceType) ||
      !apiInsuranceInfo
    );
  };

  const combinationInsurance = (data) => {
    return (
      <div
        className={`${
          data?.combinationInsuranceSelected
            ? "insurance-option option-selected"
            : data?.noInsuranceSelected && !isLoadingInsurance
            ? "insurance-option option-disabled"
            : "insurance-option"
        }
          ${
            isLoadingInsurance &&
            selectedInsuranceName !== insurance.type.selectCombinationInsurance
              ? "insurance-option option-disabled"
              : "insurance-option"
          }`}
      >
        <div className="insurance-option-header">
          { textToDisplay && textToDisplay.insuranceOptions?.headers?.combinationInsurance }
        </div>
        <div className="insurance-option-details">
          {contentfulCombinationInsurance &&
            documentToReactComponents(
              contentfulCombinationInsurance,
              insuranceOptionDetails
            )}
        </div>
        {apiInsuranceInfo?.combinationInsuranceSelected ? (
          !isLoadingInsurance ? (
            <div className="insurance-option-selected">
              <div>
                <div className="icon-container">
                  <div className="icon"></div>
                </div>
                <div className="text">
                  {textToDisplay && textToDisplay.insuranceOptions?.common?.info?.addedText}
                </div>
              </div>
              <div
                className="deselect-insurance-option"
                onClick={handleCancelInsurance}
              >
                {textToDisplay && textToDisplay.insuranceOptions?.common?.buttons?.removeText}
              </div>
            </div>
          ) : (
            <div className="spinner-button-wrapper">
              <div className="spinner-button">
                <CircularProgress className="button-loading-spinner" size={6} />
                <div className="loading-text loading-text-removal">
                  {isAgreementClicked
                    ? textToDisplay.insuranceOptions?.common?.buttons
                        ?.loadingText
                    : textToDisplay.insuranceOptions?.common?.buttons
                        ?.toRemoveText}
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="insurance-option-selectable">
            <div className="insurance-option-price">
              €{" "}
              {data?.combinationInsurancePrice ??
                insuranceInfo?.combinationInsurancePrice}
            </div>
            <div className="insurance-option-selector">
              <GenericButton
                className="button-select-insurance-option"
                disabledState={disableButton(
                  insurance.type.selectCombinationInsurance
                )}
                onClick={() => {
                  performInsuranceAction(
                    insurance.action.selectInsuranceOption,
                    insurance.type.selectCombinationInsurance
                  );
                  onInsuranceSelectSetName(
                    insurance.type.selectCombinationInsurance
                  );
                }}
              >
                {(!isLoadingInsurance ||
                  (isLoadingInsurance &&
                    selectedInsuranceName !==
                      insurance.type.selectCombinationInsurance)) &&
                  textToDisplay?.insuranceOptions?.common?.buttons?.selectText}
                {isLoadingInsurance &&
                  selectedInsuranceName ===
                    insurance.type.selectCombinationInsurance && (
                    <div className="spinner-button-wrapper">
                      <div className="spinner-button">
                        <CircularProgress
                          className="button-loading-spinner"
                          size={8}
                        />
                        <div className="loading-text">
                          {
                            textToDisplay?.insuranceOptions?.common?.buttons
                              ?.loadingText
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </GenericButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  const insuranceOptions = (
    <div className="insurance-options">
      {parseFloat(insuranceInfo?.cancellationInsurancePrice || "0") >
        0 && cancellationInsurance(apiInsuranceInfo)}
      {parseFloat(insuranceInfo?.travelInsurancePrice || "0") > 0 &&
        travelInsurance(apiInsuranceInfo)}
      {parseFloat(insuranceInfo?.combinationInsurancePrice || "0") > 0 &&
        combinationInsurance(apiInsuranceInfo)}
    </div>
  );

  const clearInsuranceSelection = () => {
    if (!disableButton()) {
      performInsuranceAction(
        insurance.action.selectInsuranceOption,
        insurance.type.selectNoInsurance
      );
      onInsuranceSelectSetName(insurance.type.selectNoInsurance);
    }
  };

  const insuranceResignationAndAgreement = (
    <>
      {
        selectedInsuranceName !== insurance.type.selectRemoveInsurance
        && selectedInsuranceName !== insurance.type.selectNoInsurance
        && selectedInsuranceName !== null
        ? (
          <div className={`insurance-agreement ${isLoadingInsurance ? "checkbox-option-disabled" : ""}`} onClick={handleAgreementClick} 
          >
            <div className="agreement-text">
              {textToDisplay && textToDisplay.agreementTextLong}
            </div>

            <div className="agreement-checkbox">
              <div
                className={
                  apiInsuranceInfo && apiInsuranceInfo.insuranceConditionsApproved
                    ? "checkbox-container active"
                    : "checkbox-container inactive"
                }
              >
                <div className="checkbox"></div>
              </div>
              <div className="text-container">
                {isLoadingAgreement ? (
                  <div className="spinner-button-wrapper">
                    <div className="spinner-button">
                      <CircularProgress
                        className="button-loading-spinner"
                        size={6}
                      />
                      <div className="loading-text">
                        {
                          textToDisplay?.insuranceOptions?.common?.buttons
                            ?.loadingText
                        }
                      </div>
                    </div>
                  </div>
                ) : textToDisplay && textToDisplay.agreementText}
              </div>
            </div>
          </div>
        ): (
          <div
            className={`insurance-resignation ${isLoadingInsurance ? "checkbox-option-disabled" : ""}`}
            
            onClick={clearInsuranceSelection}
          >
          <div
            className={
              apiInsuranceInfo && apiInsuranceInfo.noInsuranceSelected
                ? "checkbox-container active"
                : "checkbox-container inactive"
            }
          >
            <div className="checkbox"></div>
          </div>
          <div className="text-container">
            {textToDisplay && textToDisplay.resignationText}
            {isLoadingInsurance &&
              selectedInsuranceName === insurance.type.selectNoInsurance && (
                <div className="spinner-checkbox">
                  <CircularProgress className="button-loading-spinner" size={6} />
                </div>
              )}
          </div>
        </div>
        )}
    </>
  );
  
  return (
    <div className="insurance-component">
      <div className="content">
        {isLoading || onFirstLoad ? (
          <>
            <div className="progress-circle-payment">
              <CircularProgress className="spinner-circle" />
              <div className="circle-bottom-text">
                <p className="circle-title-bottom">{spinnerDescription}</p>
              </div>
            </div>
          </>
        ) : (
          <>
            {header}
            {insuranceIntro}
            {insuranceOptions}
            {insuranceResignationAndAgreement}
          </>
        )}
      </div>
    </div>
  );
};

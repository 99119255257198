import { Fragment, useState } from "react";
import { ReactComponent as IconMealKLM } from "../../assets/icons/klm/meals-S.svg";
import { ReactComponent as IconPraticalKLM } from "../../assets/icons/klm/pratical-icon-klm.svg";
import { ReactComponent as IconMealTransavia } from "../../assets/icons/transavia/meals-S.svg";
import { ReactComponent as IconPraticalTransavia } from "../../assets/icons/transavia/practical-icon-transavia.svg";
import {
  accomodationCategories,
  accomodationCategoriesNL,
  klmAccomodationIcons,
  klmAccomodationIconsNL,
  primaryCategories,
  transaviaAccomodationIcons,
  transaviaAccomodationIconsNL,
} from "../../common/constants/accomodationCategories";
import { SessionTypes } from "../../common/constants/sessionTypes";
import { accommodationOptionsEnum } from "../../common/enums/accommodationOptions";
import { affiliates } from "../../common/enums/affiliates";
import { culturesCodeEnum } from "../../common/enums/cultureCodesEnum";
import { GetMealType } from "../../common/enums/meal-types";
import { GetAffiliate } from "../../services/general.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { AccommodationOptionDetailsModal } from "../accommodation-option-details-modal/accommodation-option-details-modal";
import { AccommodationSelectButton } from "../accommodation-select-button/accommodation-select-button.component";
import { HotelImageFlipper } from "../hotel/hotel-image-flipper/hotel-image-flipper.component";
import "./accommodation-option-card.component.scss";

export const AccommodationOptionCard = ({
  contentfulAccommodationSection,
  accommodationOptions,
  authInfo,
  contentfulAccommodationShowDetails,
  performRoomOptionSelection,
  contentfulButtons,
  showAsSingleRoom,
  numberOfRooms,
  isLoading,
  onSelect
}) => {
  const culture = LocalStorageService.getCulture();
  const [moreRoomOptions, toggleMoreRoomOptions] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isDetailsPopupOpen, setIsDetailsPopupOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [unCategorizedData, setUnCategorizedData] = useState([]);
  const singleRoomAccommodation = 1;

  const accomodationCategoriesLocalized =
    culture === culturesCodeEnum.NETHERLANDS
      ? accomodationCategoriesNL
      : accomodationCategories;

  const displayNumberRoomOptions = () => {
    if (moreRoomOptions) {
      return accommodationOptions?.length;
    }
    return accommodationOptionsEnum.defaultNumberOfOptions;
  };
  const viewRoomDetails = (room) => {
    setModalData(room);
    setCategoriesData(room.hotelRoomTypeDetails.categories);
    setUnCategorizedData(room.hotelRoomTypeDetails.uncategorized);
    setIsDetailsPopupOpen(true);
  };

  const getAffiliateIcons = () => {
    const isKLM = GetAffiliate() === affiliates.klm;
    const isNL =
      LocalStorageService.getCulture() === culturesCodeEnum.NETHERLANDS;

    if (isKLM) {
      return isNL ? klmAccomodationIconsNL : klmAccomodationIcons;
    } else {
      return isNL ? transaviaAccomodationIconsNL : transaviaAccomodationIcons;
    }
  };

  const onSelectRoomOption = async (roomOptionKey) => {
    if (!isLoading) {
      setSelectedRoom(roomOptionKey);
      await performRoomOptionSelection(roomOptionKey);
      toggleMoreRoomOptions(false);
      onSelect();
    }
  };

  const renderKeyRoomDetails = (uncategorized, limit = 3) => {
    const affiliateIcons = getAffiliateIcons();
    const isKLM = GetAffiliate() === affiliates.klm;

    const prioritizedItems = uncategorized.filter((item) =>
      primaryCategories.some((category) =>
        item
          .toLowerCase()
          .includes(accomodationCategories[category]?.toLowerCase())
      )
    );

    const remainingItems = uncategorized.filter(
      (item) => !prioritizedItems.includes(item)
    );

    const finalItems = limit
      ? [...prioritizedItems, ...remainingItems].slice(0, limit)
      : [...prioritizedItems, ...remainingItems];

    return finalItems.map((item, index) => {
      const matchedCategory = Object.entries(
        accomodationCategoriesLocalized
      ).find(([_, keyword]) =>
        item.toLowerCase().includes(keyword.toLowerCase())
      );

      const formatTextWithBoldNumbers = (text) => {
        return text.split(/(\d+)/).map((part, i) =>
          /\d+/.test(part) ? (
            <span key={i} className="bold-number">
              {part}
            </span>
          ) : (
            part
          )
        );
      };

      let icon;

      if (matchedCategory) {
        const [category] = matchedCategory;
        const iconKey = accomodationCategoriesLocalized[category];
        icon = affiliateIcons[iconKey];
      } else {
        icon = isKLM ? <IconPraticalKLM /> : <IconPraticalTransavia />;
      }

      return (
        <div key={index} className="amenities-category-option">
          {icon}
          <div>{formatTextWithBoldNumbers(item)}</div>
        </div>
      );
    });
  };

  const getPriceLabel = (room) => {
    if (room.selected) {
      return (
        <div className="inclusive">
          {contentfulAccommodationSection &&
            contentfulAccommodationSection.inclusive}
        </div>
      );
    } else {
      return (
        <div className="additional-cost">
          {`${Number(room.price) >= 0 ? "+" : "-"} €${
            room.price !== "" ? room.price : 0
          }`}
          <span>
            {" "}
            {contentfulAccommodationSection &&
              contentfulAccommodationSection.pricePerPerson}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      {accommodationOptions?.map(
        (room, index) =>
          displayNumberRoomOptions() > index && (
            <Fragment key={index}>
              <div
                key={index}
                className={`accommodation-option-card-container ${
                  isLoading && selectedRoom !== room.roomOptionKey
                    ? "opacity-50"
                    : ""
                } ${
                  room.selected || accommodationOptions.selected
                    ? "accommodation-options-selected"
                    : ""
                }`}
              >
                <div className="hotel-image">
                  <HotelImageFlipper hotelImages={room.images} />
                </div>
                <div className="hotel-description">
                  <div className="accommodation-info">
                    <div className="accommodation-option-card__title-description">
                      <span className="title-description__highlight">
                        {room?.numberOfRooms}x{" "}
                      </span>
                      {`${room?.roomDescription?.toLowerCase()}`}
                    </div>

                    <div className="amenities-options">
                      <div
                        className={`amenities-item ${
                          room?.roomDescriptions?.length <=
                          singleRoomAccommodation
                            ? "amenities-item-one"
                            : "amenities-item-group"
                        }`}
                      >
                        <div className="amenities-item-meal">
                          {GetAffiliate() === affiliates.klm ? (
                            <IconMealKLM
                              className={`${
                                room?.mealIncluded
                                  ? "amenities-icon"
                                  : "amenities-icon-not-selected"
                              }`}
                            />
                          ) : (
                            <IconMealTransavia
                              className={`${
                                room?.mealIncluded
                                  ? "amenities-icon-selected"
                                  : "amenities-icon-not-selected"
                              }`}
                            />
                          )}
                          {GetMealType(room.mealTypeCode)}
                        </div>
                      </div>
                      <div className="amenities-item">
                        <div className="amenities-item-categ ory">
                          <div className="amenities-items-container">
                            <div className={`accommodation-categories-options`}>
                              {renderKeyRoomDetails(
                                room?.hotelRoomTypeDetails?.uncategorized || [],
                                3
                              )}
                            </div>
                            {authInfo === SessionTypes.defaultType && (
                              <div
                                className="show-more-accommodation-btn-mobile"
                                onClick={() => viewRoomDetails(room)}
                              >
                                {contentfulAccommodationShowDetails}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {authInfo === SessionTypes.defaultType && (
                        <div
                          className="show-more-accommodation-btn-desktop"
                          onClick={() => viewRoomDetails(room)}
                        >
                          {contentfulAccommodationShowDetails}
                        </div>
                      )}
                    </div>
                  </div>
                  <>
                    {numberOfRooms === 1 && (
                      <div className="price-selection-control">
                        {room.numberOfRooms === 1 && getPriceLabel(room)}
                        <div className="select-accommodation-button">
                          {showAsSingleRoom && (
                            <AccommodationSelectButton
                              isSelected={room.selected}
                              contentfulButtons={contentfulButtons}
                              roomInfo={room?.roomOptionKey}
                              onClick={onSelectRoomOption}
                              isLoading={isLoading}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
              {modalData && (
                <AccommodationOptionDetailsModal
                  isOpen={isDetailsPopupOpen}
                  handleClose={() => setIsDetailsPopupOpen(false)}
                  roomDetails={modalData}
                  getAffiliateIcons={getAffiliateIcons}
                  categoriesData={categoriesData}
                  uncategorizedData={unCategorizedData}
                  renderKeyRoomDetails={renderKeyRoomDetails}
                  contentfulData={contentfulAccommodationSection}
                />
              )}
            </Fragment>
          )
      )}
    </>
  );
};

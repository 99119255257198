export const contentType = {
  BUTTONS: "buttons",
  HEADER: "header",
  RESULTS_PAGE: "resultsPage",
  HOLIDAY_PAGE: "holidayPage",
  HOLIDAY_STEPPER: "holidayStepper",
  HOLIDAY_PAGE_SUMMARY: "holidayPageSummary",
  HOLIDAY_PAGE_PRICE_MATRIX: "holidayPagePriceMatrix",
  PRICE_CHANGE_MESSAGE: "customModals",
  GENERAL_MODAL: "customModals",
  FILTERS: "filters",
  HOLIDAYS_COMPONENT: "holidaysComponent",
  SEARCH_BAR: "searchBar",
  ROOM_ALLOCATION: "roomAllocation",
  SORT: "sort",
  CHECKOUT_PASSENGER_DETAILS: "checkoutPassengerDetails",
  CHECKOUT_FORM_VALIDATORS: "checkoutFormValidators",
  GENERAL: "general",
  CAR_COMPONENT: "carComponent",
  PAYMENT_PAGE: "paymentPage",
  ADYEN_COMPONENT: "adyenPayment",
  FOOTER: "footer",
  EXTRAS_PAGE: "extrasPage",
  CONFIRMATION_PAGE: "confirmationPage",
  EXCEPTIONS: "exceptions",
  LUGGAGE_SELECTION: "luggageSelection",
  TRANSFERS_COMPONENT: "transfersComponent",
  WAITING_MODAL: "waitingModal",
  WAITING_MODAL_MESSAGE: "waitingModalMessage",
};

import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";
import { breakpoints } from "../../common/constants/breakpoints";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { action } from "../../common/constants/action";
import "./holiday-package-flight-filters.scss";

export const HolidayPackageFlightFiltersComponent = ({
  contentfulButtons,
  contentfulFilters,
  apiFilterInfo,
  apiApplyFlightFilters,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [filterInfo, setStateFilterInfo] = useState(null);
  const [isOutboundFilterActive, setStateIsOutboundFilterActive] =
    useState(false);
  const [isInboundFilterActive, setStateIsInboundFilterActive] =
    useState(false);
  const [isFlightStopsFilterActive, setStateIsFlightStopsFilterActive] =
    useState(false);
  const [mobileShowFiltersModal, setStateMobileShowFiltersModal] =
    useState(false);
  const mobileFiltersModalRef = useRef(
    document.getElementById("mobileFiltersModalRef")
  );
  const desktopOutboundFilterRef = useRef(
    document.getElementById("desktopOutboundFilterRef")
  );
  const desktopInboundFilterRef = useRef(
    document.getElementById("desktopInboundFilterRef")
  );
  const desktopFlightStopsFilterRef = useRef(
    document.getElementById("desktopFlightStopsFilterRef")
  );
  const windowSizeDetector = useWindowSizeDetector();

  useEffect(() => {
    windowSizeDetector.width < breakpoints.SM
      ? setIsMobile(true)
      : setIsMobile(false);

    windowSizeDetector.width > breakpoints.SM &&
    windowSizeDetector.width < breakpoints.LG
      ? setIsTablet(true)
      : setIsTablet(false);
  });

  useEffect(() => {
    if (isMobile || isTablet) {
      mobileManageClickEventHandler();
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (apiFilterInfo) {
      setStateFilterInfo(structuredClone(apiFilterInfo));
    }
  }, [apiFilterInfo]);

  useEffect(() => {}, [filterInfo]);

  const setCase = (input) => {
    return input[0].toLowerCase() + input.slice(1);
  };

  const isOutboundFilterSelected = () => {
    return filterInfo?.outBoundFlightTimeFilters.find(
      (option) => option.selected
    )
      ? true
      : false;
  };

  const isInboundFilterSelected = () => {
    return filterInfo?.inboundFlightTimeFilters.find(
      (option) => option.selected
    )
      ? true
      : false;
  };

  const isFlightStopsFilterSelected = () => {
    return filterInfo?.flightStopsFilters.find((option) => option.selected)
      ? true
      : false;
  };

  const isFilterSelected = () => {
    return isOutboundFilterSelected ||
      isInboundFilterSelected ||
      isFlightStopsFilterSelected
      ? true
      : false;
  };

  const getOutboundFiltersSelected = () => {
    const selectedOptions = filterInfo?.outBoundFlightTimeFilters
      .filter((option) => option.selected)
      .map((option) => option.filterCode)
      .map((filterCode) => contentfulFilters[0]?.fields?.[setCase(filterCode)])
      .join(", ");
    return selectedOptions;
  };

  const getInboundFiltersSelected = () => {
    const selectedOptions = filterInfo?.inboundFlightTimeFilters
      .filter((option) => option.selected)
      .map((option) => option.filterCode)
      .map((filterCode) => contentfulFilters[0]?.fields?.[setCase(filterCode)])
      .join(", ");
    return selectedOptions;
  };

  const getFlightStopsFiltersSelected = () => {
    const selectedOptions = filterInfo?.flightStopsFilters
      .filter((option) => option.selected)
      .map((option) => option.filterCode)
      .map((filterCode) => contentfulFilters[0]?.fields?.[setCase(filterCode)])
      .join(", ");
    return selectedOptions;
  };

  const setIsOutboundFilterActive = () => {
    setStateIsOutboundFilterActive(!isOutboundFilterActive);
    setStateIsInboundFilterActive(false);
    setStateIsFlightStopsFilterActive(false);
    desktopOutboundFilterManageClickEvent();
  };

  const setIsInboundFilterActive = () => {
    setStateIsOutboundFilterActive(false);
    setStateIsInboundFilterActive(!isInboundFilterActive);
    setStateIsFlightStopsFilterActive(false);
    desktopInboundFilterManageClickEvent();
  };

  const setIsFlightStopsFilterActive = () => {
    setStateIsOutboundFilterActive(false);
    setStateIsInboundFilterActive(false);
    setStateIsFlightStopsFilterActive(!isFlightStopsFilterActive);
    desktopFlightStopsFilterManageClickEvent();
  };

  const filterOptionCheckbox = (option) => {
    if (option) {
      return (
        <div
          className="filter-option-checkbox-container"
          onClick={(event) => {
            handleOnClickCheckbox(event, option);
          }}
        >
          <div
            className={
              option.selected
                ? "checkbox-container active"
                : "checkbox-container inactive"
            }
          >
            <div className="checkbox"></div>
          </div>
          <div className="text-container">
            <span>
              {contentfulFilters[0]?.fields?.[setCase(option.filterCode)]}
            </span>
            <span>{`(`}</span>
            <span>{option.startTime}</span>
            <span>{`-`}</span>
            <span>{option.endTime}</span>
            <span>{`)`}</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const filterOptionRadiobutton = (option) => {
    if (option) {
      return (
        <div
          className="filter-option-radiobutton-container"
          onClick={(event) => {
            handleOnClickRadiobutton(event, option);
          }}
        >
          <div
            className={
              option.selected
                ? "radiobutton-container active"
                : "radiobutton-container inactive"
            }
          >
            <div className="radiobutton"></div>
          </div>
          <div className="text-container">
            <span>
              {contentfulFilters[0]?.fields?.[setCase(option.filterCode)]}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleOnClickCheckbox = (event, option) => {
    event.stopPropagation();
    option.selected = !option.selected;
    setStateFilterInfo(structuredClone(filterInfo));
    if (!isMobile && !isTablet) {
      applyFlightFilters();
      setStateIsOutboundFilterActive(false);
      setStateIsInboundFilterActive(false);
      setStateIsFlightStopsFilterActive(false);
    }
  };

  const handleOnClickRadiobutton = (event, option) => {
    event.stopPropagation();
    filterInfo.flightStopsFilters.forEach((e) => (e.selected = false));
    option.selected = !option.selected;
    setStateFilterInfo(structuredClone(filterInfo));
    if (!isMobile && !isTablet) {
      applyFlightFilters();
      setStateIsOutboundFilterActive(false);
      setStateIsInboundFilterActive(false);
      setStateIsFlightStopsFilterActive(false);
    }
  };

  const applyFlightFilters = () => {
    if (isFilterSelected()) {
      const flightFilters = {
        outBoundTimeFilters: filterInfo?.outBoundFlightTimeFilters
          .filter((option) => option.selected)
          .map((option) => option.filterCode),
        inBoundTimeFilters: filterInfo?.inboundFlightTimeFilters
          .filter((option) => option.selected)
          .map((option) => option.filterCode),
        stopsFilter:
          filterInfo?.flightStopsFilters.find((option) => option.selected)
            ?.filterCode ?? "",
      };
      apiApplyFlightFilters(flightFilters);
    }
  };

  const clearFiltersRequestPayload = {
    outBoundTimeFilters: [],
    inBoundTimeFilters: [],
    stopsFilter: "",
  };

  const clearAllFilters = () => {
    apiApplyFlightFilters(clearFiltersRequestPayload);
    if (isMobile || isTablet) {
      setStateMobileShowFiltersModal(false);
    }
  };

  const filtersSummary = (
    <div className="selected-filters-summary">
      <div>
        {filterInfo?.numberOfFiltersApplied ?? 0}&nbsp;
        {contentfulFilters && contentfulFilters[0]?.fields?.filtersSelected}
      </div>
      {filterInfo?.numberOfFiltersApplied > 0 && contentfulButtons ? (
        <div className="button-clear-all-filters" onClick={clearAllFilters}>
          {contentfulButtons[0]?.fields?.clearAllFilters}
        </div>
      ) : null}
    </div>
  );

  const mobileManageClickEventHandler = () => {
    document.addEventListener("click", mobileClickEventHandler, false);
    return () => {
      document.removeEventListener("click", mobileClickEventHandler, false);
    };
  };

  const mobileClickEventHandler = (event) => {
    if (
      mobileFiltersModalRef.current &&
      !mobileFiltersModalRef.current.contains(event.target)
    ) {
      setStateMobileShowFiltersModal(false);
      mobileCancelFilterSelection();
    }
  };

  const mobileHandleFiltersVisibility = (event) => {
    event?.stopPropagation();
    setStateMobileShowFiltersModal(!mobileShowFiltersModal);
  };

  const mobileFilterButtonOnClickAction = (requestedAction) => {
    switch (requestedAction) {
      case action.cancel:
        mobileCancelFilterSelection();
        mobileHandleFiltersVisibility();
        break;
      case action.apply:
        applyFlightFilters();
        mobileHandleFiltersVisibility();
        break;
      default:
        break;
    }
  };

  const mobileFiltersVisibilityTrigger = (
    <div className="mobile-filters-visibility-trigger">
      <div className="filters-selector" onClick={mobileHandleFiltersVisibility}>
        <div className="filters-icon"></div>
        <div className="filters-text">
          {contentfulFilters && contentfulFilters[0]?.fields?.filters}
        </div>
        <div
          className={
            filterInfo?.filtersAreApplied
              ? "filters-counter-container active"
              : "filters-counter-container"
          }
        >
          <div className="filters-counter">
            {filterInfo?.numberOfFiltersApplied ?? 0}
          </div>
        </div>
      </div>
    </div>
  );

  const mobileFiltersButtons = (
    <div className="mobile-filters-buttons">
      <div
        className="button"
        onClick={() => {
          mobileFilterButtonOnClickAction(action.cancel);
        }}
      >
        {contentfulButtons && contentfulButtons[0]?.fields?.cancel}
      </div>
      <div
        className="button"
        onClick={() => [mobileFilterButtonOnClickAction(action.apply)]}
      >
        {contentfulButtons && contentfulButtons[0]?.fields?.applyFilters}
      </div>
    </div>
  );

  const mobileFiltersHeader = (
    <div className="mobile-filters-header">
      <div>
        {contentfulFilters && contentfulFilters[0]?.fields?.flightFilters}
      </div>
      <div className="selected-filters-summary">
        <div>
          {filterInfo?.numberOfFiltersApplied ?? 0}&nbsp;
          {contentfulFilters && contentfulFilters[0]?.fields?.filtersSelected}
        </div>
        {filterInfo?.numberOfFiltersApplied > 0 && contentfulButtons ? (
          <div className="button-clear-all-filters" onClick={clearAllFilters}>
            {contentfulButtons[0]?.fields?.clearAllFilters}
          </div>
        ) : null}
      </div>
    </div>
  );

  const mobileOutboundFlightTimeFilter = (
    <div className="flight-filter-type">
      <div className="flight-filter-header">
        <div className="filter-header-icon outbound-flight"></div>
        <div>{contentfulFilters[0]?.fields?.outboundFlightDepartureTimes}</div>
      </div>
      <div className="flight-filter-content">
        {filterInfo?.outBoundFlightTimeFilters?.map((option, index) => {
          return (
            <div key={index} className="flight-filter-option">
              {filterOptionCheckbox(option)}
            </div>
          );
        })}
      </div>
    </div>
  );

  const mobileInboundFlightTimeFilter = (
    <div className="flight-filter-type">
      <div className="flight-filter-header">
        <div className="filter-header-icon inbound-flight"></div>
        <div>{contentfulFilters[0]?.fields?.returnFlightDepartureTimes}</div>
      </div>
      <div className="flight-filter-content">
        {filterInfo?.inboundFlightTimeFilters?.map((option, index) => {
          return (
            <div key={index} className="flight-filter-option">
              {filterOptionCheckbox(option)}
            </div>
          );
        })}
      </div>
    </div>
  );

  const mobileNumberOfStopsFilter = (
    <div className="flight-filter-type">
      <div className="flight-filter-header">
        <div className="filter-header-icon number-of-stops"></div>
        <div>{contentfulFilters[0]?.fields?.numberOfStops}</div>
      </div>
      <div className="flight-filter-content">
        {filterInfo?.flightStopsFilters?.map((option, index) => {
          return (
            <div key={index} className="flight-filter-option">
              {filterOptionRadiobutton(option)}
            </div>
          );
        })}
      </div>
    </div>
  );

  const mobileFiltersSelector = (
    <div className="mobile-filters-selector">
      {mobileOutboundFlightTimeFilter}
      {mobileInboundFlightTimeFilter}
      {mobileNumberOfStopsFilter}
    </div>
  );

  const mobileFiltersModal = (
    <>
      {mobileShowFiltersModal && (
        <>
          {createPortal(
            <div className="mobile-filters-modal">
              <div className="background">
                <div
                  ref={mobileFiltersModalRef}
                  id="mobileFiltersModalRef"
                  className="content"
                >
                  {mobileFiltersButtons}
                  {mobileFiltersHeader}
                  {mobileFiltersSelector}
                </div>
              </div>
            </div>,
            document.getElementById("overlay-root")
          )}
        </>
      )}
    </>
  );

  const mobileFiltersElements = (
    <div className="mobile-filters-elements">
      {mobileFiltersVisibilityTrigger}
      {mobileFiltersModal}
    </div>
  );

  const mobileCancelFilterSelection = () => {
    setStateFilterInfo(structuredClone(apiFilterInfo));
  };

  const desktopOutboundFilterManageClickEvent = () => {
    document.addEventListener(
      "click",
      desktopOutboundFilterRefClickEventHandler,
      false
    );
    return () => {
      document.removeEventListener(
        "click",
        desktopOutboundFilterRefClickEventHandler,
        false
      );
    };
  };

  const desktopOutboundFilterRefClickEventHandler = (event) => {
    if (
      desktopOutboundFilterRef.current &&
      !desktopOutboundFilterRef.current.contains(event.target)
    ) {
      setStateIsOutboundFilterActive(false);
      document.removeEventListener(
        "click",
        desktopOutboundFilterRefClickEventHandler,
        false
      );
    }
  };

  const desktopInboundFilterManageClickEvent = () => {
    document.addEventListener(
      "click",
      desktopInboundFilterRefClickEventHandler,
      false
    );
    return () => {
      document.removeEventListener(
        "click",
        desktopInboundFilterRefClickEventHandler,
        false
      );
    };
  };

  const desktopInboundFilterRefClickEventHandler = (event) => {
    if (
      desktopInboundFilterRef.current &&
      !desktopInboundFilterRef.current.contains(event.target)
    ) {
      setStateIsInboundFilterActive(false);
      document.removeEventListener(
        "click",
        desktopInboundFilterRefClickEventHandler,
        false
      );
    }
  };

  const desktopFlightStopsFilterManageClickEvent = () => {
    document.addEventListener(
      "click",
      desktopFlightStopsFilterRefClickEventHandler,
      false
    );
    return () => {
      document.removeEventListener(
        "click",
        desktopFlightStopsFilterRefClickEventHandler,
        false
      );
    };
  };

  const desktopFlightStopsFilterRefClickEventHandler = (event) => {
    if (
      desktopFlightStopsFilterRef.current &&
      !desktopFlightStopsFilterRef.current.contains(event.target)
    ) {
      setStateIsFlightStopsFilterActive(false);
      document.removeEventListener(
        "click",
        desktopFlightStopsFilterRefClickEventHandler,
        false
      );
    }
  };

  const desktopOutboundFlightTimeFilter = (
    <div ref={desktopOutboundFilterRef} className="desktop-flight-filter">
      <div className="header">
        {contentfulFilters[0]?.fields?.outboundFlightDepartureTimes}
      </div>
      <div
        className={
          isOutboundFilterActive ? "dropdown-header active" : "dropdown-header"
        }
        onClick={setIsOutboundFilterActive}
      >
        <div className="icon outbound-flight"></div>
        <div className="text">
          {isOutboundFilterSelected() ? (
            getOutboundFiltersSelected()
          ) : (
            <div className="placeholder">
              {contentfulFilters[0]?.fields?.chooseAnOption}
            </div>
          )}
        </div>
        <div
          className={isOutboundFilterActive ? "caret-up" : "caret-down"}
        ></div>
      </div>
      {isOutboundFilterActive && (
        <div id="desktopOutboundFilterRef" className="dropdown-content">
          {filterInfo?.outBoundFlightTimeFilters?.map((option, index) => {
            return (
              <div key={index} className="flight-filter-option">
                {filterOptionCheckbox(option)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  const desktopInboundFlightTimeFilter = (
    <div ref={desktopInboundFilterRef} className="desktop-flight-filter">
      <div className="header">
        {contentfulFilters[0]?.fields?.returnFlightDepartureTimes}
      </div>
      <div
        className={
          isInboundFilterActive ? "dropdown-header active" : "dropdown-header"
        }
        onClick={setIsInboundFilterActive}
      >
        <div className="icon inbound-flight"></div>
        <div className="text">
          {isInboundFilterSelected() ? (
            getInboundFiltersSelected()
          ) : (
            <div className="placeholder">
              {contentfulFilters[0]?.fields?.chooseAnOption}
            </div>
          )}
        </div>
        <div
          className={isInboundFilterActive ? "caret-up" : "caret-down"}
        ></div>
      </div>
      {isInboundFilterActive && (
        <div id="desktopInboundFilterRef" className="dropdown-content">
          {filterInfo?.inboundFlightTimeFilters?.map((option, index) => {
            return (
              <div key={index} className="flight-filter-option">
                {filterOptionCheckbox(option)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  const desktopNumberOfStopsFilter = (
    <div ref={desktopFlightStopsFilterRef} className="desktop-flight-filter">
      <div className="header">
        {contentfulFilters[0]?.fields?.numberOfStops}
      </div>
      <div
        className={
          isFlightStopsFilterActive
            ? "dropdown-header active"
            : "dropdown-header"
        }
        onClick={setIsFlightStopsFilterActive}
      >
        <div className="icon number-of-stops"></div>
        <div className="text">
          {isFlightStopsFilterSelected() ? (
            getFlightStopsFiltersSelected()
          ) : (
            <div className="placeholder">
              {contentfulFilters[0]?.fields?.chooseAnOption}
            </div>
          )}
        </div>
        <div
          className={isFlightStopsFilterActive ? "caret-up" : "caret-down"}
        ></div>
      </div>
      {isFlightStopsFilterActive && (
        <div id="desktopFlightStopsFilterRef" className="dropdown-content">
          {filterInfo?.flightStopsFilters?.map((option, index) => {
            return (
              <div key={index} className="flight-filter-option">
                {filterOptionRadiobutton(option)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  const desktopFiltersElements = (
    <div className="desktop-filters-elements">
      {desktopOutboundFlightTimeFilter}
      {desktopInboundFlightTimeFilter}
      {desktopNumberOfStopsFilter}
    </div>
  );

  const holidayPackageFlightFilters = (
    <div className="holiday-package-flight-filters">
      {isMobile || isTablet ? (
        <>{mobileFiltersElements}</>
      ) : (
        <>{desktopFiltersElements}</>
      )}
      {filtersSummary}
    </div>
  );

  return (
    <>
      {filterInfo?.showFilters && (
        <div className="holiday-package-flight-filters-container">
          {holidayPackageFlightFilters}
        </div>
      )}
    </>
  );
};

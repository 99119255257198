import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useCallback,
} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import en from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import "./../datepicker.styles.scss";
import { pages } from "../../../common/enums/pages";
import { findAvailableDate, formatWeekDay } from "../../../services/date-formatter.service";
import RegularDatepickerChildrenComponent from "../datepicker-children/regular-datepicker/regular-datepicker-children-component";
import PriceDatepickerChildrenComponent from "../datepicker-children/price-datepicker/price-datepicker-children-component";
import axios from "axios";
import { GetAffiliate, GetApiUrl } from "../../../services/general.service";
import { format, isSameDay, isWithinInterval, parseISO } from "date-fns";
import { cultureType } from "../../../common/enums/cultureTypes";
import { Tooltip } from "@mui/material";
import { affiliates } from "../../../common/enums/affiliates";
import { DateFormat } from "../../../common/constants/dateFormats";

const klmComponentsProps = {
  tooltip: {
    sx: {
      bgcolor: "#00A1DE",
      color: "white",
    },
  },
  arrow: {
    sx: {
      color: "#00A1DE",
    },
  },
};

const transaviaComponentProps = {
  tooltip: {
    sx: {
      bgcolor: "#2800A0",
      color: "white",
    },
  },
  arrow: {
    sx: {
      color: "#2800A0",
    },
  },
};

const DatepickerWrapperComponent = ({
  contentfulButtons,
  contentfulSearchBar,
  formatSelectedDates,
  showPriceCalendarFunction,
  isMobile,
  minDateOfAll,
  maxDateOfAll,
  culture,
  isOpen,
  setIsOpen,
  selectedDates,
  startDate,
  endDate,
  setEndDate,
  departureDateFlexibility,
  onChange,
  handleClick,
  toggleDatePickerOpen,
  handleTravelFlexibility,
  isDateRangeValid,
  isRegularCalendar,
  setIsRegularCalendar,
  holidayListKey,
  setDurationPriceCalendar,
  priceCalendarDuration,
  priceCalendarPreselected,
  isSameDate,
  defaultSearchUsed,
  hoverEndDate,
  setHoverEndDate
}) => {
  registerLocale("nl", nl);
  const componentRef = useRef();
  const [priceCalendarData, setPriceCalendarData] = useState({});
  const [blackOutDates, setBlackOutDates] = useState([]);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [isWaiting, setIsWaiting] = useState(null);

  const handleMouseEnter = (event) => {
    const requiredClasses = [
      "day-cell",
      "price",
      "offer-price",
      "react-datepicker__day",
      "react-datepicker__week",
      "react-datepicker__month",
    ];
    if (
      !requiredClasses.some((className) =>
        event.target.classList?.contains(className)
      )
    ) {
      clearHover();
    }
  };

  useEffect(() => {
    document.addEventListener("mouseenter", handleMouseEnter, true);
    return () => {
      document.removeEventListener("mouseenter", handleMouseEnter, false);
    };
  }, []);

  useEffect(() => {
    componentRef?.current?.setOpen(isOpen);
  }, [isOpen, isRegularCalendar]);

  const handleDayHover = (date) => {
    setHoveredDate(date);
    const hoveredEndDate = findAvailableDate(date, priceCalendarData)
    setHoverEndDate(hoveredEndDate)
  };

  const clearHover = () => {
    setHoveredDate(null);
  };

  const CustomInput = forwardRef(({ props }, ref) => (
    <button className="custom-input" onClick={toggleDatePickerOpen}>
      <span className="dates-placeholder">
        <span className="dates-placeholder-wrapper">
          {formatSelectedDates(selectedDates?.departureDate)} -{" "}
          {formatSelectedDates(selectedDates?.returnDate)}
        </span>
        <span className="travel-flexibility-placeholder">
          {selectedDates?.departureDateFlexibility > 0 && (
            <span>&plusmn; {selectedDates?.departureDateFlexibility} </span>
          )}
        </span>
      </span>
      <div className="dropdown-caret-placeholder">
        {(isOpen && <div className="dropdown-caret rotate"></div>) ||
          (!isOpen && <div className="dropdown-caret"></div>)}
      </div>
    </button>
  ));

  const getPriceCalendarData = async (duration) => {
    setDurationPriceCalendar(duration);
    setIsWaiting(true);
    const response = await axios.get(
      `${GetApiUrl()}/HolidaySearch/${holidayListKey}/priceCalendar?duration=${
        duration
      }`
    );

    setIsWaiting(false);
    setPriceCalendarData(response.data);

    const blackOutDatesMapped = mapBlackOutDates(response.data.blackOutDates);
    setBlackOutDates(blackOutDatesMapped);
  };

  const mapBlackOutDates = (data) => {
    return data.map((date) => new Date(date));
  };

  const getPriceInfo = (date) => {
    const dateString = format(date, DateFormat.YYYY_MM_DD);
    if (date && priceCalendarData.availableDates) {
      return (
        priceCalendarData.availableDates.find(
          (item) => item.departureDate === dateString
        ) || null
      );
    }
    return null;
  };

  const renderDayContents = useCallback(
    (day, date) => {
      const priceInfo = getPriceInfo(date);
      return priceInfo ? (
        <div className="day-cell">
          <span>{day}</span>
          {priceInfo ? (
            <div
              className={`${priceInfo.offerPrice ? "offer-price" : "price"}`}
            >
              €{priceInfo.displayPrice}
            </div>
          ) : (
            <div className="no-price">&nbsp;</div>
          )}
        </div>
      ) : (
        <Tooltip
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -8],
                },
              },
            ],
          }}
          componentsProps={
            GetAffiliate() === affiliates.klm
              ? klmComponentsProps
              : transaviaComponentProps
          }
          title={
            contentfulSearchBar &&
            contentfulSearchBar.tooltipDepartureDateNotPossible
          }
          arrow
        >
          <div className="day-cell">
            <span>{day}</span>
            {priceInfo ? (
              <div
                className={`${priceInfo.offerPrice ? "offer-price" : "price"}`}
              >
                €{priceInfo.displayPrice}
              </div>
            ) : (
              <div className="no-price">&nbsp;</div>
            )}
          </div>
        </Tooltip>
      );
    },
    [priceCalendarData]
  );

  const settleHiglightClasses =
    (date) => {
      if (hoveredDate && !isSameDay(hoveredDate, startDate)) {
        if (isSameDay(date, hoveredDate)) {
          return "highlight-start-date";
        }
        if (isSameDay(date, parseISO(hoverEndDate?.returnDate))) {
          return "highlight-end-date";
        }
        if (
          isWithinInterval(date, {
            start: hoveredDate,
            end: parseISO(hoverEndDate?.returnDate),
          })
        ) {
          return "highlight-range";
        }
      return "";
    }
    return "";
  };

  return (
    <div className="datepicker-component" onChange={onChange}>
      <div className="datepicker-label" onMouseLeave={clearHover}>
        {isRegularCalendar ? (
          <DatePicker
            locale={culture === cultureType.nl ? nl : en}
            selected={null}
            onChange={onChange}
            onClickOutside={handleClick}
            shouldCloseOnSelect={false}
            minDate={minDateOfAll}
            maxDate={maxDateOfAll}
            showDisabledMonthNavigation
            showPreviousMonths={false}
            startDate={startDate}
            endDate={endDate}
            selectsDisabledDaysInRange
            monthsShown={2}
            peekNextMonth
            formatWeekDay={formatWeekDay()}
            selectsRange
            ref={componentRef}
            className={"datepicker-active"}
            dateFormat={DateFormat.DD_MMM_YYYY}
            inlineFocusSelectedMonth
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            customInput={
              <CustomInput
                selectedDates={selectedDates}
                isOpen={isOpen}
                toggleDatePickerOpen={toggleDatePickerOpen}
                formatSelectedDates={formatSelectedDates}
              />
            }
            calendarClassName={`regular-datepicker ${
              showPriceCalendarFunction && !defaultSearchUsed ? "regular-shift-picker" : ""
            } `}
          >
            <RegularDatepickerChildrenComponent
              pages={pages}
              departureDateFlexibility={departureDateFlexibility}
              handleTravelFlexibility={handleTravelFlexibility}
              contentfulSearchBar={contentfulSearchBar}
              setIsOpen={setIsOpen}
              contentfulButtons={contentfulButtons}
              handleClick={handleClick}
              isDateRangeValid={isDateRangeValid}
              showPriceCalendarFunction={showPriceCalendarFunction}
              isMobile={isMobile}
              isRegularCalendar={isRegularCalendar}
              setIsRegularCalendar={setIsRegularCalendar}
              defaultSearchUsed={defaultSearchUsed}
            />
          </DatePicker>
        ) : (
          <DatePicker
            locale={culture === cultureType.nl ? nl : en}
            selected={null}
            onChange={onChange}
            onClickOutside={handleClick}
            shouldCloseOnSelect={false}
            minDate={minDateOfAll}
            maxDate={maxDateOfAll}
            showDisabledMonthNavigation
            showPreviousMonths={false}
            startDate={
              priceCalendarPreselected && !isSameDate ? startDate : null
            }
            endDate={
              priceCalendarPreselected && !isSameDate
                ? endDate
                : null
            }
            monthsShown={2}
            peekNextMonth
            formatWeekDay={formatWeekDay()}
            selectsRange
            ref={componentRef}
            className={"datepicker-active"}
            dateFormat={DateFormat.DD_MMM_YYYY}
            inlineFocusSelectedMonth
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            customInput={
              <CustomInput
                selectedDates={selectedDates}
                isOpen={isOpen}
                toggleDatePickerOpen={toggleDatePickerOpen}
                formatSelectedDates={formatSelectedDates}
              />
            }
            calendarClassName={`price-datepicker ${
              showPriceCalendarFunction ? "price-shift-picker" : ""
            }`}
            renderDayContents={renderDayContents}
            dayClassName={settleHiglightClasses}
            onDayMouseEnter={handleDayHover}
            onDayMouseLeave={clearHover}
            excludeDates={blackOutDates}
          >
            <PriceDatepickerChildrenComponent
              pages={pages}
              departureDateFlexibility={departureDateFlexibility}
              handleTravelFlexibility={handleTravelFlexibility}
              contentfulSearchBar={contentfulSearchBar}
              setIsOpen={setIsOpen}
              contentfulButtons={contentfulButtons}
              handleClick={handleClick}
              isDateRangeValid={isDateRangeValid}
              showPriceCalendarFunction={showPriceCalendarFunction}
              isMobile={isMobile}
              isRegularCalendar={isRegularCalendar}
              setIsRegularCalendar={setIsRegularCalendar}
              holidayListKey={holidayListKey}
              priceCalendarData={priceCalendarData}
              getPriceCalendarData={getPriceCalendarData}
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              hoverEndDate={hoverEndDate}
              clearHover={clearHover}
              isWaiting={isWaiting}
              defaultSearchUsed={defaultSearchUsed}
              priceCalendarDuration={priceCalendarDuration}
              setDurationPriceCalendar={setDurationPriceCalendar}
            />
          </DatePicker>
        )}
      </div>
    </div>
  );
};

export default DatepickerWrapperComponent;
